import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NumberWithCommas from '../../../../../helpers/NumberWithCommas';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../css/print.css';
import 'moment/locale/th';
import DatabaseService from 'services/database';
import { NotificationManager } from 'react-notifications';

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
  },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(5),
    marginTop: 20,
    fontSize: '1.3rem',
  },
  paper: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  content: {
    display: 'none',
    padding: '5px 35px 0px 35px',
  },
  label: {
    fontWeight: 'bold',
    margin: 20,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    fontSize: '1.3rem',
  },
  // tr: {
  //     backgroundColor: '#97C1E0 !important'
  // },
  [`@media print`]: {
    content: {
      display: 'block',
    },
    // tr: {
    //     backgroundColor: '#97C1E0 !important',
    // },
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

const MySwal = withReactContent(Swal);

const swal = html =>
  MySwal.fire({
    title: 'Please Confirm',
    html: html,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    reverseButtons: true,
  });

const PrintInvoice = props => {
  const { open, setOpen, rowSelected, setOpenDialog, setStatusPrinted, form } = props;
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [isPrinting, setIsPrinting] = useState(false);
  const [startPrint, setStartPrint] = useState(false);
  const [total, setTotal] = useState(0);
  const row = rowSelected;
  const handleClose = () => {
    setOpen(false);
  };

  const handlePrint = () => {
    setIsPrinting(true);
    window.print();
    setIsPrinting(false);
  };

  window.onafterprint = () => {
    handleClose();
    if (setOpenDialog) setOpenDialog(false);

    if (row.status === 'Created') {
      swal(`Confirm printing as successful and mark <br/> Invoice Number
                     ${row.invoice_no} <br/> as Printed?`).then(result => {
        if (result.value) {
          DatabaseService.updateInvoiceStatus({
            id: row.id,
            status: 'Printed',
            update_status_by: authUser.email,
            update_status_at: new Date(),
            form: form,
          })
            .then(() => {
              NotificationManager.success('Status has been updated', 'Success');
              if (setStatusPrinted) setStatusPrinted();
              // if (setCustomStatus) setCustomStatus('Printed');
            })
            .catch(err => {
              NotificationManager.error(err, 'Fail');
            });
        }
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setStartPrint(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (startPrint) {
      handlePrint();
    }
  }, [startPrint]);

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <div className={classes.content}>
        <Grid item xs={12}>
          <Box>
            <>
              <div className={classes.root}>
                <div style={{ textAlign: 'right' }}>
                  <h1>ใบวางบิล</h1>
                </div>

                <hr style={{ margin: '-3px 0px 25px 0px' }} />

                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}>
                    <strong>ลูกค้า:</strong>
                    <span> {row.customer_id}</span>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>วันที่:</strong>
                    <span>
                      {' '}
                      {moment(new Date(row.invoice_date.toDate()))
                        .locale('th')
                        .add(543, 'year')
                        .format('LL')}
                    </span>
                  </Grid>
                </Grid>

                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}>
                    <strong>Sub:</strong>
                    <span> {row.sub ?? ''}</span>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>เลขที่:</strong>
                    <span> {row.invoice_no}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}>
                    <strong>ที่อยู่:</strong>
                    <span> {row.company_address}</span>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>อ้างอิง:</strong>
                    <span> {row.container}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}>
                    <strong>โทรศัพท์:</strong>
                    <span> {row.mobile}</span>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>ผู้สร้างรายการ:</strong>
                    <span> {row.created_by}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}></Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>วันที่จัดส่งสินค้า:</strong>
                    <span>
                      {' '}
                      {moment(new Date(row.delivery_date.toDate()))
                        .locale('th')
                        .add(543, 'year')
                        .format('LL')}
                    </span>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}></Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>ที่อยู่จัดส่ง:</strong>
                    <span> {row.delivery_address}</span>
                  </Grid>
                </Grid>
              </div>

              <div style={{ marginTop: 20 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <table className={classes.table}>
                      <thead>
                        <tr className={classes.tr}>
                          <th className={classes.th}>Type</th>
                          <th className={classes.th}>Bill No.</th>
                          <th className={classes.th}>Pack</th>
                          <th className={classes.th}>Description</th>
                          <th className={classes.th}>Sub</th>
                          <th className={classes.th}>Warehouse</th>
                          {row.calculation_type === 'Kg' && <th className={classes.th}>Weight</th>}
                          {row.calculation_type === 'M3' && <th className={classes.th}>CBM</th>}
                          <th className={classes.th}>Price</th>
                          <th className={classes.th}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.productNumberList?.map(item => (
                          <tr key={item.po_number}>
                            <td className={classes.td}>{item.type === 'Other' ? '' : item.productType || item.type}</td>
                            <td className={classes.td}>{item.po_number}</td>
                            <td className={classes.td}>{item.packs}</td>
                            <td className={classes.td}>{item.description}</td>
                            <td className={classes.td}>{item.sub_customer}</td>
                            <td className={classes.td}>{item.type === 'Other' ? '' : row.warehouse}</td>
                            {row.calculation_type === 'Kg' && (
                              <td className={classes.td}>
                                {!isNaN(item.weight) && typeof item.weight === 'number' ? item.weight.toFixed(2) : ''}
                              </td>
                            )}
                            {row.calculation_type === 'M3' && (
                              <td className={classes.td}>
                                {!isNaN(item.cbm) && typeof item.cbm === 'number' ? item.cbm.toFixed(4) : ''}
                              </td>
                            )}
                            <td className={classes.td}>{NumberWithCommas(item.price?.toFixed(2))}</td>
                            <td className={classes.td}>{NumberWithCommas(item.total?.toFixed(2))}</td>
                          </tr>
                        ))}

                        <tr>
                          <td className={classes.td}></td>
                          <td className={classes.td}>Grand Total</td>
                          <td className={classes.td}></td>
                          <td className={classes.td}></td>
                          <td className={classes.td}></td>
                          <td className={classes.td}></td>
                          <td className={classes.td}></td>
                          <td className={classes.td}></td>
                          <td className={classes.td}>{NumberWithCommas(row.total.toFixed(2))}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.root}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                    <strong>หมายเหตุ</strong>{' '}
                    <span>
                      กรณีสินค้ามีปัญหาลูกค้าแจ้งกลับบริษัท ภายใน 3 วัน หลังจากวันที่ได้รับสินค้าเรียบร้อยแล้ว
                      มิฉะนั้นบริษัทจะไม่รับผิดชอบทุกกรณี{' '}
                    </span>
                    <p style={{ marginLeft: 95 }}>กรณีค่าขนส่งไม่ถึง 500 บาท / คิดค่าขนส่งขั้นต่ำ 500 บาท</p>
                    <p style={{ marginLeft: 95 }}>
                      จัดส่งสินค้าฟรี กรุงเทพ และปริมณฑลเท่านั้น และ/หรือคิดค่ารถ 500 บาทขึ้นไปตามระยะทาง
                    </p>
                    <p style={{ marginLeft: 95 }}>กรณีส่งต่างจังหวัดบริษัทจัดส่งขนส่งภายในประเทศให้ชำระค่าขนส่งปลายทาง</p>
                  </Grid>
                </Grid>
              </div>

              {(form?.remarkCustomer || row.remark_customer) && (
                <div className={classes.root}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <strong>หมายเหตุสำหรับลูกค้า {row.customer_id}</strong>{' '}
                      <span>{form?.remarkCustomer || row.remark_customer}</span>
                    </Grid>
                  </Grid>
                </div>
              )}
            </>

            {!isPrinting && (
              <div className={classes.root} style={{ marginTop: 35 }}>
                <Box component="span" displayPrint="none">
                  <Grid container justify="center">
                    <Button variant="contained" onClick={handleClose}>
                      Go Back
                    </Button>
                    <Button style={{ marginLeft: 10 }} color="primary" variant="contained" onClick={handlePrint}>
                      Print
                    </Button>
                  </Grid>
                </Box>
              </div>
            )}
          </Box>
        </Grid>
      </div>
    </Dialog>
  );
};

export default PrintInvoice;
