import React, { useState, useEffect } from 'react';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Box, Button, List, ListItem, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import LinearProgress from '@material-ui/core/LinearProgress';

const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Import Arrived WH China', isActive: true },
];

const useStyles = makeStyles(theme => ({
    card: {
        margin: '0 auto',
        padding: 10,
        backgroundColor: lighten('#FFFFFF', 0.1),
        // backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
    linear: {
        marginBottom: 10
    }
}));

const DragNdrop = (props) => {
    const { handleUploadFiles, getRootProps, getInputProps, open, acceptedFiles, pending, errorUpload } = props;
    const classes = useStyles();
    const [uploading, setUploading] = useState(false);

    // const { getRootProps, getInputProps, open } = useDropzone({
    //     noClick: true,
    //     noKeyboard: true,
    // });

    const files = acceptedFiles.map(file => (
        <ListItem key={file.path}>
            {file.path} - {file.size} bytes
        </ListItem>
    ));

    return (
        <Card className={classes.card}>
            <Box {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Typography>Drag 'n' drop some files here</Typography>
                <Button variant="outlined" type="button" onClick={open} disabled={pending}>
                    Open File Dialog
                </Button>
            </Box>
            <aside>
                <Typography component="h4" variant="inherit">
                    Files
                </Typography>
                <List>{files}</List>
            </aside>

            {errorUpload && (<div style={{ color: 'red', marginBottom: 5 }}>
                Excel not match, please check your template and version again!!
            </div>)}

            {pending && <LinearProgress color="secondary" className={classes.linear} />}

            <Button variant="outlined" type="button" onClick={handleUploadFiles} disabled={pending || acceptedFiles.length === 0}>
                Upload
            </Button>
        </Card>
    );
};

export default DragNdrop;