import React, { useState, useEffect } from 'react';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#EFEFEF',
    },
  },
};

const columns = [
  {
    name: 'Product Number',
    selector: 'productNumber',
    sortable: true,
  },
  {
    name: 'Container',
    selector: 'container',
    sortable: true,
    width: '360px',
  },
  {
    name: 'Packs',
    selector: 'packs',
    sortable: true,
  },
  {
    name: 'CBM',
    selector: 'cbm',
    sortable: true,
    cell: row => (!isNaN(row.cbm) && typeof row.cbm === 'number' ? row.cbm.toFixed(4) : ''),
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
  },
  {
    name: 'Customer',
    selector: 'customer_id',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
  {
    name: 'Import Result',
    selector: 'importResult',
    sortable: true,
    width: '360px',
  },
];

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const AlertTable = props => {
  const { alerts } = props;
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <DataTable title="Notifications" columns={columns} data={alerts} customStyles={customStyles} />
    </Card>
  );
};

export default AlertTable;
