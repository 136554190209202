import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { deepOrange, orange, pink, red } from '@material-ui/core/colors';

const EditButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: deepOrange[700],
        },
    },
}))(Button);

const DeleteButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: pink[700],
        },
    },
}))(Button);

export { EditButton, DeleteButton };

