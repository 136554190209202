import { firestore, storage } from '../firebase';
import { fetchError, fetchStart, fetchSuccess } from '../redux/actions';
import { fetchAllCustomers, fetchAllDrivers, fetchAllSub } from '../redux/actions/Master';

const DatabaseService = {
  updateOrder: (id, data, authUser) => {
    data.height = parseFloat(data.height);
    data.length = parseFloat(data.length);
    data.cbm = parseFloat(data.cbm);
    data.packs = parseFloat(data.packs);
    data.qty = parseFloat(data.qty);
    data.weight = parseFloat(data.weight);
    data.width = parseFloat(data.width);

    data.po_number = data.po_number.toUpperCase();
    data.container = data.container.toUpperCase();
    data.tracking_number = data.tracking_number.toUpperCase();
    data.customer_id = data.customer_id.toUpperCase();
    data.type = data.productType;

    switch (data.status) {
      case 'WH China':
        data.update_arrived_wh_china = new Date();
        data.update_arrived_wh_china_by = authUser.email;
        data.update_wh_china_remark = data.remark;
        data.packs_arrived_wh_china = parseFloat(data.packs);
        // data.qty_arrived_wh_china = parseFloat(data.qty);
        break;
      case 'Closed Container':
        data.update_closed_container = new Date();
        data.update_closed_container_by = authUser.email;
        data.update_closed_container_remark = data.remark;
        data.packs_closed_container = parseFloat(data.packs);
        // data.qty_closed_container = parseFloat(data.qty);
        break;
      case 'WH Thai':
        data.update_arrived_wh_thai = new Date();
        data.update_arrived_wh_thai_by = authUser.email;
        data.update_arrived_wh_thai_remark = data.remark;
        data.packs_arrived_wh_thai = parseFloat(data.packs);
        // data.qty_arrived_wh_thai = parseFloat(data.qty);
        break;
      case 'Delivering':
        data.update_delivering = new Date();
        data.update_delivering_by = authUser.email;
        data.update_delivering_remark = data.remark;
        data.packs_delivering = parseFloat(data.packs);
        // data.qty_delivering = parseFloat(data.qty);
        break;
      case 'Delivered':
        data.update_delivered = new Date();
        data.update_delivered_by = authUser.email;
        data.update_delivered_remark = data.remark;
        data.packs_delivered = parseFloat(data.packs);
        // data.qty_delivered = parseFloat(data.qty);
        break;
      case 'Cancel':
        data.cancelled_by = authUser.email;
        data.cancelled_at = new Date();
        break;
    }

    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`orders/${id}`);
      docRef.get().then(async doc => {
        if (doc.exists) {
          const logRef = firestore.collection('update_history');
          logRef
            .add({
              po_id: doc.id,
              po_number: doc.data().po_number,
              updated_at: new Date(),
              updated_by: authUser.email,
              old_data: doc.data(),
              new_data: data,
            })
            .then(() => {
              docRef
                .update(data)
                .then(res => {
                  resolve(res);
                })
                .catch(err => {
                  reject(err);
                });
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });
  },

  updateOrderStatusByProductNumber: (id, data) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`orders/${id}`);
      docRef
        .update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // getAllDrivers: () => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       let drivers = [];
  //       const ref = firestore.collection('users').where('type', '==', 'driver');
  //       ref.onSnapshot(async snapShot => {
  //         snapShot.forEach(doc => {
  //           if (doc.exists) {
  //             drivers.push(doc.data());
  //           }
  //         });
  //       });
  //       resolve(drivers);
  //     } catch (err) {
  //       reject(err);
  //     }
  //   });
  // },

  // getAllDriversDispatch: () => {
  //   return dispatch => {
  //     try {
  //       dispatch(fetchStart());
  //       let drivers = [];
  //       const ref = firestore.collection('users').where('type', '==', 'driver');
  //       ref.onSnapshot(snapShot => {
  //         snapShot.forEach(doc => {
  //           if (doc.exists) {
  //             drivers.push(doc.data());
  //           }
  //         });
  //       });
  //       dispatch(fetchAllDrivers(drivers));
  //       dispatch(fetchSuccess());
  //     } catch (err) {
  //       dispatch(fetchError(err));
  //     }
  //   };
  // },

  getAllDriversDispatch: () => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        let drivers = [];
        const ref = firestore.collection('drivers');
        ref.onSnapshot(snapShot => {
          snapShot.forEach(doc => {
            if (doc.exists) {
              drivers.push(doc.data());
            }
          });
        });
        dispatch(fetchAllDrivers(drivers));
        dispatch(fetchSuccess());
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  getAllCustomers: () => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        let customers = [];
        const ref = firestore.collection('customers').orderBy('name', 'asc');
        ref.onSnapshot(snapShot => {
          snapShot.forEach(doc => {
            if (doc.exists) {
              customers.push(doc.data());
            }
          });
        });
        dispatch(fetchAllCustomers(customers));
        dispatch(fetchSuccess());
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  getAllSub: () => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        let sub = [];
        const ref = firestore.collection('sub').orderBy('name', 'asc');
        ref.onSnapshot(snapShot => {
          snapShot.forEach(doc => {
            if (doc.exists) {
              sub.push(doc.data());
            }
          });
        });
        dispatch(fetchAllSub(sub));
        dispatch(fetchSuccess());
      } catch (err) {
        dispatch(fetchError(err));
      }
    };
  },

  addCustmer: data => {
    return new Promise((resolve, reject) => {
      data.customer_price.map(item => {
        item.kg_ek_price = parseFloat(item.kg_ek_price);
        item.kg_sea_price = parseFloat(item.kg_sea_price);
        item.m3_ek_price = parseFloat(item.m3_ek_price);
        item.m3_sea_price = parseFloat(item.m3_sea_price);
      });

      const docRef = firestore.collection('customers');
      docRef
        .add(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateCustomer: (id, data) => {
    return new Promise((resolve, reject) => {
      if (data.customer_price)
        data.customer_price.map(item => {
          item.kg_ek_price = parseFloat(item.kg_ek_price);
          item.kg_sea_price = parseFloat(item.kg_sea_price);
          item.m3_ek_price = parseFloat(item.m3_ek_price);
          item.m3_sea_price = parseFloat(item.m3_sea_price);
        });

      const docRef = firestore.doc(`customers/${id}`);
      docRef
        .update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addSub: data => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.collection('sub');
      docRef
        .add(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateSub: (id, data) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`sub/${id}`);
      docRef
        .update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteSub: doc => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`sub/${doc.id}`);
      docRef
        .delete()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addDriver: data => {
    return new Promise((resolve, reject) => {
      data.status = 'Waiting Approve';
      const docRef = firestore.collection('drivers');
      docRef
        .add(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateDriver: (id, data) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`drivers/${id}`);
      docRef
        .update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteDriver: doc => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`drivers/${doc.id}`);
      docRef
        .delete()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateUser: (id, data) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`users/${id}`);
      docRef
        .set(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addRegistrationUser: doc => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.collection('registered_users');
      docRef
        .add(doc)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  checkUserDuplicate: data => {
    return new Promise((resolve, reject) => {
      const ref = firestore.collection('registered_users').where('email', '==', data.email);
      ref.onSnapshot(async snapShot => {
        if (snapShot.size === 0) {
          const ref = firestore.collection('users').where('email', '==', data.email);
          ref.onSnapshot(async snapShot => {
            if (snapShot.size === 0) {
              resolve(false);
            } else {
              resolve(true);
            }
          });
        } else {
          resolve(true);
        }
      });
    });
  },

  updateRegistrationUser: (id, data) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`registered_users/${id}`);
      docRef
        .update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteRegistrationUser: doc => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`registered_users/${doc.id}`);
      docRef
        .delete()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteCustomer: doc => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`customers/${doc.id}`);
      docRef
        .delete()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  uploadImages: files => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = { success: 0, error: 0 };
        let alerts = [];

        await Promise.all(
          files.map(async file => {
            const filename = file.name
              .split('.')
              .slice(0, -1)
              .join('.');

            const docRef = firestore.collection('orders').where('po_number', '==', filename);

            docRef.get().then(async snapShot => {
              if (snapShot.size > 0) {
                let id = snapShot.docs[0].id;
                // let data = snapShot.docs[0].data();

                const uploadTask = storage.ref(`/images/${filename}`).put(file);
                await uploadTask
                  .then(async res => {
                    const docRef = firestore.collection('orders').doc(id);
                    await docRef.update({
                      pictures_path: [res.metadata.fullPath],
                    });

                    result.success++;
                  })
                  .catch(err => {
                    result.error++;
                    alerts.push({ filename: file.name, message: `Error: ${err}` });
                  });
              } else {
                result.error++;
                alerts.push({ filename: file.name, message: `Error: PO Number not found!` });
              }

              resolve({ result: result, alerts: alerts });
            });
          }),
        );
      } catch (err) {
        reject(err);
      }
    });
  },

  getBillByNo: async id => {
    return new Promise((resolve, reject) => {
      firestore
        .collection('invoices')
        .where('invoice_no', '==', id)
        .get()
        .then(snapShot => {
          if (snapShot.size > 0) {
            let data = snapShot.docs[0].data();
            data.id = snapShot.docs[0].id;
            resolve(data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getDeliveryNoteByNo: async id => {
    return new Promise((resolve, reject) => {
      firestore
        .collection('delivery_notes')
        .where('delivery_note_no', '==', id)
        .get()
        .then(snapShot => {
          if (snapShot.size > 0) {
            let data = snapShot.docs[0].data();
            data.id = snapShot.docs[0].id;
            resolve(data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateOrderBillCreated: async ({ id, data }) => {
    if (id) {
      const docRef = firestore.collection('orders').doc(id);
      await docRef.update(data);
    }
  },

  addInvoice: ({ data, authUser }) => {
    return new Promise((resolve, reject) => {
      data.calculation_type_upper = data.calculation_type.toUpperCase();
      let sub = '';

      data.productNumberList.map(async item => {
        item.cbm = parseFloat(item.cbm);
        item.price = parseFloat(item.price);
        item.total = parseFloat(item.total);

        if (item.sub_customer) {
          const subArray = sub.split(',');

          if (!subArray.includes(item.sub_customer)) {
            sub += item.sub_customer;
            sub += ', ';
          }
        }
      });

      let total = data.productNumberList.reduce((sum, p) => sum + p.total, 0);
      data.total = total;
      data.sub = sub.replace(/,\s*$/, '');

      const docRef = firestore.collection('invoices');
      const periodRunning = `${data.invoice_date.getMonth()}-${data.invoice_date.getFullYear()}`;

      DatabaseService.getNewInvoiceNumber({
        periodRunning: periodRunning,
        invoiceDate: data.invoice_date,
      }).then(res => {
        const invoiceNo = res;
        data.invoice_no = invoiceNo;
        data.period_running = periodRunning;
        data.created_by = authUser.name;
        data.status = 'Created';

        docRef
          .add(data)
          .then(res => {
            data.productNumberList.map(item => {
              DatabaseService.updateOrderBillCreated({
                id: item.id,
                data: {
                  invoice_no: invoiceNo,
                  created_bill_date: new Date(),
                },
              });
            });
            resolve({ invoiceNo: data.invoice_no });
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  },

  getNewInvoiceNumber: ({ periodRunning, invoiceDate }) => {
    return new Promise((resolve, reject) => {
      const ref = firestore
        .collection('invoices')
        .where('period_running', '==', periodRunning)
        .orderBy('invoice_no', 'desc')
        .limit(1);
      const year = invoiceDate.getFullYear();
      const month = ('0' + (invoiceDate.getMonth() + 1)).slice(-2);

      ref
        .get()
        .then(snapShot => {
          if (snapShot.size > 0) {
            const last3Digits = snapShot.docs[0].data().invoice_no.slice(-6);
            const newNo = (parseInt(last3Digits) + 1).toString();
            const newInvoiceNo = `INV-${year}${month}${newNo.padStart(6, '0')}`;

            resolve(newInvoiceNo);
          } else {
            resolve(`INV-${year}${month}000001`);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // addDeliveryNote: ({ data, authUser }) => {
  //   return new Promise((resolve, reject) => {
  //     const docRef = firestore.collection('delivery_notes');
  //     const periodRunning = `${data.delivery_note_date.getMonth()}-${data.delivery_note_date.getFullYear()}`;

  //     DatabaseService.getNewDeliveryNoteNumber({
  //       periodRunning: periodRunning,
  //       invoiceDate: data.delivery_note_date,
  //     }).then(res => {
  //       const invoiceNo = res;
  //       data.delivery_note_no = invoiceNo;
  //       data.period_running = periodRunning;
  //       data.created_by = authUser.name;
  //       data.status = 'Created';

  //       let total = data.productNumberList.reduce(
  //         (a, v) => {
  //           a.packs += v.packs;
  //           a.weight += v.weight;
  //           a.cbm += v.cbm;
  //           return a;
  //         },
  //         {
  //           packs: 0,
  //           weight: 0,
  //           cbm: 0,
  //         },
  //       );

  //       data.total_pack = total.packs;
  //       data.total_weight = total.weight;
  //       data.total_cbm = total.cbm;

  //       docRef
  //         .add(data)
  //         .then(res => {
  //           data.productNumberList.map(item => {
  //             DatabaseService.updateOrderBillCreated({
  //               id: item.id,
  //               data: { created_delivery_note_date: new Date() },
  //             });
  //           });
  //           resolve({ deliveryNoteNo: data.delivery_note_no });
  //         })
  //         .catch(err => {
  //           reject(err);
  //         });
  //     });
  //   });
  // },
  // getNewDeliveryNoteNumber: ({ periodRunning, invoiceDate }) => {
  //   return new Promise((resolve, reject) => {
  //     const ref = firestore
  //       .collection('delivery_notes')
  //       .where('period_running', '==', periodRunning)
  //       .orderBy('delivery_note_no', 'desc');
  //     const year = invoiceDate.getFullYear();
  //     const month = ('0' + (invoiceDate.getMonth() + 1)).slice(-2);

  //     ref
  //       .get()
  //       .then(snapShot => {
  //         if (snapShot.size > 0) {
  //           const last3Digits = snapShot.docs[0].data().delivery_note_no.slice(-6);
  //           const newNo = (parseInt(last3Digits) + 1).toString();
  //           const newInvoiceNo = `DN-${year}${month}${newNo.padStart(6, '0')}`;

  //           resolve(newInvoiceNo);
  //         } else {
  //           resolve(`DN-${year}${month}000001`);
  //         }
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },

  fetchOrdersData: ({ authUser, status, filterSearch, searchText }) => {
    let ref = firestore.collection('/orders');

    switch (authUser.type) {
      case 'customer':
        ref = ref.where('customer_id', '==', authUser.customerId);

        if (!status === 'All') {
          ref = ref.where('status', '==', status);
        }

        if (searchText && filterSearch) {
          ref = ref
            .where(filterSearch, '>=', searchText.toUpperCase())
            .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
            .orderBy(filterSearch, 'desc');
        } else {
          ref = ref.orderBy('po_number', 'desc');
        }

        if (authUser.subId) {
          ref = ref.where('sub_customer', '==', authUser.subId);
        }

        return ref;

      // if (status === 'All') {
      //   if (searchText && filterSearch) {
      //     if (authUser.subId) {
      //       return ref
      //         .where('customer_id', '==', authUser.customerId)
      //         .where('sub_customer', '==', authUser.subId)
      //         .where(filterSearch, '>=', searchText.toUpperCase())
      //         .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
      //         .orderBy(filterSearch, 'desc');
      //     } else {
      //       return ref
      //         .where('customer_id', '==', authUser.customerId)
      //         .where(filterSearch, '>=', searchText.toUpperCase())
      //         .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
      //         .orderBy(filterSearch, 'desc');
      //     }
      //   } else {
      //     if (authUser.subId) {
      //       return ref
      //         .where('customer_id', '==', authUser.customerId)
      //         .where('sub_customer', '==', authUser.subId)
      //         .orderBy('po_number', 'desc');
      //     } else {
      //       return ref.where('customer_id', '==', authUser.customerId).orderBy('po_number', 'desc');
      //     }
      //   }
      // } else {
      //   if (searchText && filterSearch) {
      //     if (authUser.subId) {
      //       return ref
      //         .where('customer_id', '==', authUser.customerId)
      //         .where('sub_customer', '==', authUser.subId)
      //         .where('status', '==', status)
      //         .where(filterSearch, '>=', searchText.toUpperCase())
      //         .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
      //         .orderBy(filterSearch, 'desc');
      //     } else {
      //       return ref
      //         .where('customer_id', '==', authUser.customerId)
      //         .where('status', '==', status)
      //         .where(filterSearch, '>=', searchText.toUpperCase())
      //         .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
      //         .orderBy(filterSearch, 'desc');
      //     }
      //   } else {
      //     if (authUser.subId) {
      //       return ref
      //         .where('customer_id', '==', authUser.customerId)
      //         .where('sub_customer', '==', authUser.subId)
      //         .where('status', '==', status)
      //         .orderBy('po_number', 'desc');
      //     } else {
      //       return ref
      //         .where('customer_id', '==', authUser.customerId)
      //         .where('status', '==', status)
      //         .orderBy('po_number', 'desc');
      //     }
      //   }
      // }
      case 'driver':
        ref = ref.where('assigned_driver', '==', authUser.name).where('status', 'in', ['Delivering', 'Delivered']);

        if (status !== 'All') {
          ref = ref.where('status', '==', status);
        }

        if (searchText && filterSearch) {
          ref = ref
            .where(filterSearch, '>=', searchText.toUpperCase())
            .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
            .orderBy(filterSearch, 'desc');
        } else {
          ref = ref.orderBy('po_number', 'desc');
        }

        return ref;
      // if (status === 'All') {
      //   if (searchText && filterSearch) {
      //     return ref
      //       .where('assigned_driver', '==', authUser.name)
      //       .where('status', 'in', ['Delivering', 'Delivered'])
      //       .where(filterSearch, '>=', searchText.toUpperCase())
      //       .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
      //       .orderBy(filterSearch, 'desc');
      //   } else {
      //     return ref
      //       .where('assigned_driver', '==', authUser.name)
      //       .where('status', 'in', ['Delivering', 'Delivered'])
      //       .orderBy('po_number', 'desc');
      //   }
      // } else {
      //   if (searchText && filterSearch) {
      //     return ref
      //       .where('assigned_driver', '==', authUser.name)
      //       .where('status', '==', status)
      //       .where(filterSearch, '>=', searchText.toUpperCase())
      //       .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
      //       .orderBy(filterSearch, 'desc');
      //   } else {
      //     return ref
      //       .where('assigned_driver', '==', authUser.name)
      //       .where('status', '==', status)
      //       .orderBy('po_number', 'desc');
      //   }
      // }
      case 'admin':
      case 'superadmin':
      case 'frontadmin':
      case 'delivery':
      case 'accountant':
        if (status !== 'All') {
          ref = ref.where('status', '==', status);
        }

        if (searchText && filterSearch) {
          ref = ref
            .where(filterSearch, '>=', searchText.toUpperCase())
            .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
            .orderBy(filterSearch, 'desc');
        } else {
          ref = ref.orderBy('po_number', 'desc');
        }

        return ref;
      // if (status === 'All') {
      //   if (searchText && filterSearch) {
      //     return ref
      //       .where(filterSearch, '>=', searchText.toUpperCase())
      //       .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
      //       .orderBy(filterSearch, 'desc');
      //   } else {
      //     return ref.orderBy('po_number', 'desc');
      //   }
      // } else {
      //   if (searchText && filterSearch) {
      //     return ref
      //       .where('status', '==', status)
      //       .where(filterSearch, '>=', searchText.toUpperCase())
      //       .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
      //       .orderBy(filterSearch, 'desc');
      //   } else {
      //     return ref.where('status', '==', status).orderBy('po_number', 'desc');
      //   }
      // }
    }
  },

  fetchRemarkedOrdersData: ({ authUser, filterSearch, searchText }) => {
    const ref = firestore.collection('/orders');

    switch (authUser.type) {
      case 'frontadmin':
      case 'delivery':
      case 'admin':
      case 'superadmin':
        if (searchText && filterSearch) {
          return ref
            .where('remarked', '==', 1)
            .where(filterSearch, '>=', searchText.toUpperCase())
            .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
            .orderBy(filterSearch, 'desc');
        } else {
          return ref.where('remarked', '==', 1).orderBy('po_number', 'desc');
        }
    }
  },

  updateRemarkedByPo: (id, data) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`orders/${id}`);
      docRef
        .update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchManagementData: ({ authUser, collection, filterSearch, searchText }) => {
    if (authUser.type === 'admin' || authUser.type === 'superadmin') {
      let ref = firestore.collection(`/${collection}`);

      if (searchText && filterSearch) {
        ref = ref
          .where(filterSearch, '>=', searchText)
          .where(filterSearch, '<=', searchText + '\uf8ff')
          .orderBy(filterSearch, 'desc');
      } else {
        ref = ref.orderBy('name', 'asc');
      }

      return ref;

      // if (searchText && filterSearch) {
      //   return firestore
      //     .collection(`/${collection}`)
      //     .where(filterSearch, '>=', searchText)
      //     .where(filterSearch, '<=', searchText + '\uf8ff')
      //     .orderBy(filterSearch, 'desc');
      // } else {
      //   return firestore.collection(`/${collection}`).orderBy('name', 'asc');
      // }
    }
  },

  fetchDriverManagementData: ({ authUser, status, filterSearch, searchText }) => {
    if (authUser.type === 'delivery' || authUser.type === 'admin' || authUser.type === 'superadmin') {
      let ref = firestore.collection(`/drivers`);

      if (status !== 'All') {
        ref = ref.where('status', '==', status);
      }

      if (searchText && filterSearch) {
        ref = ref
          .where('name_lower', '>=', searchText)
          .where('name_lower', '<=', searchText + '\uf8ff')
          .orderBy('name_lower', 'desc');
      } else {
        ref = ref.orderBy('name', 'asc');
      }

      return ref;

      // if (status === 'All') {
      //   if (searchText && filterSearch) {
      //     return firestore
      //       .collection(`/drivers`)
      //       .where('name_lower', '>=', searchText)
      //       .where('name_lower', '<=', searchText + '\uf8ff')
      //       .orderBy('name_lower', 'desc');
      //   } else {
      //     return firestore.collection(`/drivers`).orderBy('name', 'asc');
      //   }
      // } else {
      //   if (searchText && filterSearch) {
      //     return firestore
      //       .collection(`/drivers`)
      //       .where('status', '==', status)
      //       .where('name_lower', '>=', searchText)
      //       .where('name_lower', '<=', searchText + '\uf8ff')
      //       .orderBy('name_lower', 'desc');
      //   } else {
      //     return firestore.collection(`/drivers`).where('status', '==', status).orderBy('name', 'asc');
      //   }
      // }
    }
  },

  fetchInvoicesData: ({ filterSearch, searchText, status, authUser }) => {
    let ref = firestore.collection('/invoices');

    switch (authUser.type) {
      case 'delivery':
        ref = ref.where('status', 'in', ['Checking', 'Checked']);
    }

    if (status !== 'All') {
      ref = ref.where('status', '==', status);
    }

    if (searchText && filterSearch) {
      ref = ref
        .where(filterSearch, '>=', searchText.toUpperCase())
        .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
        .orderBy(filterSearch, 'desc');
    } else {
      ref = ref.orderBy('invoice_no', 'desc');
    }

    return ref;

    // if (status === 'All') {
    //   if (searchText && filterSearch) {
    //     return ref
    //       .where(filterSearch, '>=', searchText.toUpperCase())
    //       .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
    //       .orderBy(filterSearch, 'desc');
    //   } else {
    //     return ref.orderBy('invoice_no', 'desc');
    //   }
    // } else {
    //   if (searchText && filterSearch) {
    //     return ref
    //       .where(filterSearch, '>=', searchText.toUpperCase())
    //       .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
    //       .where('status', '==', status)
    //       .orderBy(filterSearch, 'desc');
    //   } else {
    //     return ref.where('status', '==', status).orderBy('invoice_no', 'desc');
    //   }
    // }
  },

  fetchDeliveryNotesData: ({ filterSearch, searchText, status }) => {
    let ref = firestore.collection('/delivery_notes');

    if (status !== 'All') {
      ref = ref.where('status', '==', status);
    }

    if (searchText && filterSearch) {
      ref = ref
        .where(filterSearch, '>=', searchText.toUpperCase())
        .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
        .orderBy(filterSearch, 'desc');
    } else {
      ref = ref.orderBy('delivery_note_no', 'desc');
    }

    return ref;

    // if (status === 'All') {
    //   if (searchText && filterSearch) {
    //     return ref
    //       .where(filterSearch, '>=', searchText.toUpperCase())
    //       .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
    //       .orderBy(filterSearch, 'desc');
    //   } else {
    //     return ref.orderBy('delivery_note_no', 'desc');
    //   }
    // } else {
    //   if (searchText && filterSearch) {
    //     return ref
    //       .where(filterSearch, '>=', searchText.toUpperCase())
    //       .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
    //       .where('status', '==', status)
    //       .orderBy(filterSearch, 'desc');
    //   } else {
    //     return ref.where('status', '==', status).orderBy('delivery_note_no', 'desc');
    //   }
    // }
  },

  updateInvoiceStatus: ({ id, status, update_status_by, update_status_at, picturesPath, customUpdate, form }) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`invoices/${id}`);
      docRef.get().then(async doc => {
        if (doc.exists) {
          let invoiceObj = {
            status: status,
            update_status_by: update_status_by,
            update_status_at: update_status_at,
            remark_customer: form.remarkCustomer,
            remark_internal: form.remarkInternal,
          };

          if (!customUpdate) {
            switch (status) {
              case 'Uploaded':
                invoiceObj = { ...invoiceObj, pictures_path: picturesPath };
                // invoiceObj = {
                //   status: status,
                //   update_status_by: update_status_by,
                //   update_status_at: update_status_at,
                //   pictures_path: picturesPath,
                //   remark_customer: form.remarkCustomer,
                //   remark_internal: form.remarkInternal,
                // }
                break;
            }
          }

          docRef
            .update(invoiceObj)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });
  },

  addPaySlip: ({ id, update_status_by, update_status_at, picturesPath }) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`invoices/${id}`);
      docRef.get().then(async doc => {
        if (doc.exists) {
          docRef
            .update({
              update_status_by: update_status_by,
              update_status_at: update_status_at,
              pictures_path: picturesPath,
            })
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });
  },

  updateInvoiceDetail: ({ id, form, update_status_by, update_status_at }) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`invoices/${id}`);

      docRef
        .update({
          status: form.status,
          remark_customer: form.remarkCustomer,
          remark_internal: form.remarkInternal,
          update_status_by: update_status_by,
          update_status_at: update_status_at,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  uploadSlip: ({ file, pictureName }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadTask = storage.ref(`/slip/${pictureName}`).put(file);
        await uploadTask.then(res => {
          resolve(res);
        });
      } catch (err) {
        reject(err);
      }
    });
  },

  uploadDelivered: ({ file, pictureName }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadTask = storage.ref(`/delivered/${pictureName}`).put(file);
        await uploadTask.then(res => {
          resolve(res);
        });
      } catch (err) {
        reject(err);
      }
    });
  },

  updateInvoiceConfirmStatus: ({ id, status, deliveredStatus, paidStatus, form }) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`invoices/${id}`);
      docRef.get().then(async doc => {
        if (doc.exists) {
          let confirmDelivered = doc.data()?.confirm_delivered || deliveredStatus;
          let confirmPaid = doc.data()?.confirm_paid || paidStatus;

          if (confirmDelivered && confirmPaid) {
            status = 'Checked';
          } else if (confirmDelivered || confirmPaid) {
            status = 'Checking';
          }

          let obj = {
            status: status,
            confirm_delivered: confirmDelivered,
            confirm_paid: confirmPaid,
            remark_customer: form.remarkCustomer,
            remark_internal: form.remarkInternal,
          };

          docRef
            .update(obj)
            .then(() => {
              resolve(obj);
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });
  },

  cancelInvoiceStatus: ({ id, remark, cancel_by, cancel_at }) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`invoices/${id}`);
      docRef
        .update({
          status: 'Cancel',
          remark: remark,
          cancel_by: cancel_by,
          cancel_at: cancel_at,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateDeliveryNoteStatus: ({ id, status, update_status_by, update_status_at, picturesPath, driver, customUpdate }) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`delivery_notes/${id}`);

      docRef.get().then(async doc => {
        if (doc.exists) {
          const data = doc.data();

          if (status === 'Delivering' || status === 'Confirm Delivered') {
            await Promise.all(
              data.productNumberList.map(item => {
                const orderRef = firestore.doc(`orders/${item.id}`);

                orderRef.get().then(doc => {
                  const data = doc.data();
                  if (
                    status === 'Delivering' &&
                    (data.status === 'WH China' || data.status === 'Closed Container' || data.status === 'WH Thai')
                  ) {
                    orderRef.update({
                      status: 'Delivering',
                      assigned_driver: driver,
                      update_delivering: update_status_at,
                      update_delivering_by: update_status_by,
                      packs_delivering: data.packs,
                    });
                  }

                  if (status === 'Confirm Delivered' && data.status === 'Delivering') {
                    orderRef.update({
                      status: 'Delivered',
                      update_delivered: update_status_at,
                      update_delivered_by: update_status_by,
                      packs_delivered: data.packs,
                    });
                  }
                });
              }),
            );
          }

          let deliveryNoteObj = {
            status: status,
            update_status_at: update_status_at,
            update_status_by: update_status_by,
          };

          if (!customUpdate) {
            switch (status) {
              case 'Delivering':
                deliveryNoteObj = {
                  ...deliveryNoteObj,
                  driver: driver,
                  driver_original: data.driver,
                };
                break;
              case 'Delivered':
                deliveryNoteObj = {
                  ...deliveryNoteObj,
                  pictures_path: picturesPath,
                };
                break;
              // default:
              //   deliveryNoteObj = {
              //     status: status,
              //     update_status_at: update_status_at,
              //     update_status_by: update_status_by,
              //   }
            }
          }

          docRef
            .update(deliveryNoteObj)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        }
      });

      // let deliveryNoteObj = {
      //   status: status,
      //   update_status_at: update_status_at,
      //   update_status_by: update_status_by,
      // };

      // if (!customUpdate) {
      //   switch (status) {
      //     case 'Delivering':
      //       deliveryNoteObj = {
      //         status: status,
      //         update_status_by: update_status_by,
      //         update_status_at: update_status_at,
      //         driver: driver
      //       }
      //       break;
      //     case 'Delivered':
      //       deliveryNoteObj = {
      //         status: status,
      //         update_status_by: update_status_by,
      //         update_status_at: update_status_at,
      //         pictures_path: picturesPath
      //       }
      //       break;
      //     // default:
      //     //   deliveryNoteObj = {
      //     //     status: status,
      //     //     update_status_at: update_status_at,
      //     //     update_status_by: update_status_by,
      //     //   }
      //   }
      // }

      // docRef
      //   .update(deliveryNoteObj)
      //   .then(res => {
      //     resolve(res);
      //   })
      //   .catch(err => {
      //     reject(err);
      //   });

      // if (status === 'Delivered' && picturesPath?.length > 0) {
      //   docRef
      //     .update({
      //       status: status,
      //       update_status_by: update_status_by,
      //       update_status_at: update_status_at,
      //       pictures_path: picturesPath
      //     })
      //     .then(res => {
      //       resolve(res);
      //     })
      //     .catch(err => {
      //       reject(err);
      //     });
      // } else {
      //   docRef
      //     .update({
      //       status: status,
      //       update_status_by: update_status_by,
      //     })
      //     .then(res => {
      //       resolve(res);
      //     })
      //     .catch(err => {
      //       reject(err);
      //     });
      // }
    });
  },

  cancelDeliveryNoteStatus: ({ id, remark, cancel_by, cancel_at }) => {
    return new Promise((resolve, reject) => {
      const docRef = firestore.doc(`delivery_notes/${id}`);
      docRef
        .update({
          status: 'Cancel',
          remark: remark,
          cancel_by: cancel_by,
          cancel_at: cancel_at,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // fetchReceivableBalance: ({ filterSearch, searchText }) => {
  //   const ref = firestore.collection('/invoices');

  //   if (searchText && filterSearch) {
  //     return ref
  //       .where(filterSearch, '>=', searchText.toUpperCase())
  //       .where(filterSearch, '<=', searchText.toUpperCase() + '\uf8ff')
  //       .where('status', '==', 'Checking')
  //       .where('confirm_paid', '==', false)
  //       .orderBy(filterSearch, 'desc');
  //   } else {
  //     return ref
  //       .where('status', '==', 'Checking')
  //       .where('confirm_paid', '==', false)
  //       .orderBy('invoice_no', 'desc');
  //   }
  // },
};

export default DatabaseService;
