import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, fade, InputBase } from '@material-ui/core';
import LanguageSwitcher from '../LanguageSwitcher';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/Search';
import AppsMenu from './AppsMenu';
import HeaderNotifications from './HeaderNotifications';
import HeaderMessages from './HeaderMessages';
import Hidden from '@material-ui/core/Hidden';
import Logo from '../Logo';
import SearchPopover from '../SearchPopover';
import { fetchSearch, filterSearch } from 'redux/actions/Search';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 64,
    [theme.breakpoints.up('md')]: {
      minHeight: 72,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 30,
      backgroundColor: fade(theme.palette.common.dark, 0.38),
      color: fade(theme.palette.common.white, 0.7),
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
      '&:focus': {
        backgroundColor: fade(theme.palette.common.dark, 0.58),
        color: fade(theme.palette.common.white, 0.7),
      },
    },
  },
  langRoot: {
    borderLeft: `solid 1px ${fade(theme.palette.common.dark, 0.15)}`,
    minHeight: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minHeight: 64,
    },
  },
  iconBtn: {
    color: fade(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filterList } = useSelector(({ search }) => search);
  const { searchText } = useSelector(({ search }) => search);

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [searchText, setSearchText] = useState('');
  const [tempText, setTempText] = useState('');
  const [searchBy, setSearchBy] = useState('');

  const handleChangeSearch = (e) => {
    if (e.target.value) {
      setTempText(e.target.value);
      // dispatch(fetchSearch(e.target.value));
    } else {
      setTempText('');
      dispatch(fetchSearch(''));
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = ({ key, name }) => {
    // setSearchText('');
    setSearchBy(name);
    dispatch(filterSearch(key));
    setAnchorEl(null);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      dispatch(fetchSearch(tempText));
    }
  }

  const handleSubmit = () => {
    dispatch(fetchSearch(tempText));
  }

  useEffect(() => {
    setSearchBy(filterList[0]?.name)
    dispatch(filterSearch(filterList[0]?.key));
    setTempText('');
  }, [filterList]);

  return (
    <Toolbar className={classes.root}>
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      <Logo ml={2} color="white" />
      <Box flex={1} />
      {filterList.length > 0 &&
        <Hidden smDown>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <div style={{ color: 'white' }}>{searchBy || 'Search By...'} </div> <ArrowDropDownIcon style={{ color: '#fff' }} />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {filterList.map((item) => (
              <MenuItem key={item.key} onClick={() => handleFilter({ key: item.key, name: item.name })}>{item.name}</MenuItem>
            ))}
          </Menu>

          <Box pr={3} className={classes.searchRoot}>
            <InputBase
              placeholder={'Search here...'}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => handleChangeSearch(e)}
              value={tempText}
              onKeyDown={handleKeyDown}
            />
            <SearchIcon style={{ cursor: 'pointer' }} onClick={handleSubmit} />
          </Box>
        </Hidden>
      }
      <Hidden mdUp>
        <SearchPopover iconClassName={classes.iconBtn} />
      </Hidden>
      {/* <AppsMenu />
      <HeaderMessages /> */}
      <HeaderNotifications />
      <Box className={classes.langRoot}>
        <LanguageSwitcher />
      </Box>
    </Toolbar >
  );
};

export default Header;
