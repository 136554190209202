import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles } from '@material-ui/core/styles';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import { firestore } from '../../../../../firebase';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import PrintByContainer from '../Print/print';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        position: 'relative',
    },
    dialogTitleRoot: {
        '& .MuiTypography-h6': {
            fontSize: 18,
            color: theme.palette.common.white,
        },
        backgroundColor: '#2090E9',
    },
    appBar: {
        position: 'relative',
    },
    card: {
        margin: '0 auto',
        padding: 10,
        backgroundColor: lighten('#FFFFFF', 0.1),
        width: '100%'
    },
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(5),
        marginTop: 20
    },
    paper: {
        textAlign: "center",
        whiteSpace: "nowrap",
    },
    content: {
        padding: '35px 35px 0px 35px',
    },
    label: {
        fontWeight: 'bold',
        margin: 20,
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    tr: {
        backgroundColor: '#97C1E0 !important'
    },
    [`@media print`]: {
        tr: {
            backgroundColor: '#97C1E0 !important',
        },
    },
    td: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
    },
    th: {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: 8,
    }
}));

const defaultValues = {
    customer_id: "",
    company_address: "",
    mobile: "",
    delivery_note_no: "xxxxxxxxxx",
    delivery_note_date: new Date(),
};

const validationSchema = yup.object().shape({
    container: yup.string().required("Container is required"),
});

const PrintByContainerDialog = (props) => {
    const { open, setOpen } = props;
    const classes = useStyles();
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema), defaultValues });
    const [customers, setCustomers] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [pending, setPending] = useState(false);
    const [containerId, setContainerId] = useState();
    const [containerList, setContainerList] = useState([]);
    const [openDetail, setOpenDetail] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [invoices, setInvoices] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    const fetchContainer = () => {
        const docRef = firestore.collection('delivery_notes');

        docRef.onSnapshot(snapShot => {
            let tempDataArray = [];
            snapShot.forEach(doc => {
                if (doc.exists) {
                    let data = doc.data();
                    data.id = doc.id;

                    // let total = data.productNumberList.reduce((a, v) => {
                    //     a.packs += v.packs;
                    //     a.weight += v.weight;
                    //     a.cbm += v.cbm
                    //     return a
                    // }, {
                    //     packs: 0,
                    //     weight: 0,
                    //     cbm: 0,
                    // });

                    // data.calculation = { pack: total.packs, weight: total.weight, cbm: total.cbm };

                    tempDataArray = [
                        ...tempDataArray,
                        data,
                    ];
                }
            });

            setInvoices(tempDataArray);
            const containers = tempDataArray.map((item) => item['container']);
            const uniqueContainer = containers.filter((elem, index, self) => index === self.indexOf(elem));
            const arr = uniqueContainer.map(item => ({ container: item })).sort((a, b) => (a.container > b.container ? 1 : -1));
            setContainerList(arr);
        });
    }

    const fetchCustomers = () => {
        const container = invoices.filter(item => item.container === containerId);
        const customers = container.map((item) => item['customer_id']);
        setSelectedCustomer(customers);
        const uniqueCustomer = customers.filter((elem, index, self) => index === self.indexOf(elem));
        const arr = uniqueCustomer.map(item => ({ customer_id: item, containerId: containerId, checked: true })).sort((a, b) => (a.customer_id > b.customer_id ? 1 : -1));

        setCustomers(arr);
    };

    const fetchDrivers = () => {
        const container = invoices.filter(item => item.container === containerId);
        const drivers = container.map((item) => item['driver']);
        setSelectedDriver(drivers);
        const uniqueDriver = drivers.filter((elem, index, self) => index === self.indexOf(elem));
        const arr = uniqueDriver.map(item => ({ driver: item, containerId: containerId, checked: true })).sort((a, b) => (a.driver > b.driver ? 1 : -1));

        setDrivers(arr);
    };

    const handleDialog = () => {
        setOpen(false);
    }

    const handleChecked = (e, i) => {
        customers[i].checked = e.target.checked;

        const selectedCustomer = customers.map(item => {
            if (item.checked) {
                setErrorMessage(false);
                return item.customer_id;
            }
        });

        setSelectedCustomer(selectedCustomer);
    };

    const handleCheckedDriver = (e, i) => {
        drivers[i].checked = e.target.checked;

        const selectedDriver = drivers.map(item => {
            if (item.checked) {
                setErrorMessage(false);
                return item.driver;
            }
        });

        setSelectedDriver(selectedDriver);
    };

    const handlePrint = () => {
        if (selectedCustomer.find(item => item) && selectedDriver.find(item => item))
            setOpenDetail(true);
        else
            setErrorMessage(true);
    }

    useEffect(() => {
        fetchContainer();
    }, []);

    useEffect(() => {
        if (containerId) {
            fetchCustomers();
            fetchDrivers();
        }
    }, [containerId]);

    return (
        <Dialog open={open} onClose={handleClose} className={classes.dialogRoot} maxWidth={'sm'} fullWidth>
            <DialogTitle className={classes.dialogTitleRoot}>
                Print by Container
            </DialogTitle>
            <form onSubmit={handleSubmit(handlePrint)}>
                <DialogContent dividers>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="container"
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        size="small"
                                        onChange={(event, item) => {
                                            setContainerId(item?.container);
                                            onChange(item?.container);
                                        }}
                                        value={containerList.find(v => v.container === value) || {}}
                                        options={containerList}
                                        getOptionLabel={option => option.container?.toString() || ""}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Container"
                                                variant="outlined"
                                                error={errors.container && true}
                                                helperText={errors.container?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>

                        {containerId &&
                            <>
                                <Grid item xs={12}>
                                    <p>Customer ID</p>
                                    <Paper style={{ maxHeight: 200, overflow: 'auto', paddingLeft: '10px' }} >
                                        <List>
                                            {
                                                customers.map((v, i) => {
                                                    return (
                                                        <div key={`${v.customer_id}${containerId}`}>
                                                            <FormControlLabel
                                                                control={<Checkbox defaultChecked={v.checked || true} name="checked" onChange={e => handleChecked(e, i)} />}
                                                                label={v.customer_id}
                                                            />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </List>
                                    </Paper>
                                    {/* {errorMessage && <p style={{ color: 'red', marginTop: '10px' }}>Customer ID required</p>} */}
                                </Grid>

                                <Grid item xs={12}>
                                    <p>Driver</p>
                                    <Paper style={{ maxHeight: 200, overflow: 'auto', paddingLeft: '10px' }} >
                                        <List>
                                            {
                                                drivers.map((v, i) => {
                                                    return (
                                                        <div key={`${v.driver}${containerId}`}>
                                                            <FormControlLabel
                                                                control={<Checkbox defaultChecked={v.checked || true} name="checked" onChange={e => handleCheckedDriver(e, i)} />}
                                                                label={v.driver}
                                                            />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </List>
                                    </Paper>
                                    {errorMessage && <p style={{ color: 'red', marginTop: '10px' }}>Customer ID and Driver is required</p>}
                                </Grid>
                            </>
                        }

                    </Grid>
                    <Box display="flex" justifyContent="flex-end" mb={4} mt={4}>
                        <Button onClick={handleDialog} disabled={pending}>Cancel</Button>
                        <Box ml={2}>
                            <Button type="submit" variant="contained" color="primary" disabled={pending}>
                                Print
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </form>

            {openDetail && <PrintByContainer
                invoices={invoices.filter(item => item.container === containerId &&
                    selectedCustomer.includes(item.customer_id) &&
                    selectedDriver.includes(item.driver)).sort((a, b) => a.delivery_note_no > b.delivery_note_no ? 1 : -1)}
                open={openDetail}
                setOpenDetail={setOpenDetail}
                setOpenDialog={setOpen}
            />}
        </Dialog >
    );
};

export default PrintByContainerDialog;