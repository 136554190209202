import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const RBACChildren = ({ allowedRoles, children }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  let access = false;

  if (!access) access = authUser && authUser.type && allowedRoles.includes(authUser.type);

  return access && children;
};

RBACChildren.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element,
};

export default RBACChildren;
