import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import NumberWithCommas from '../../../../../helpers/NumberWithCommas';
import NewWindow from 'react-new-window';
import '../css/print.css';
import 'moment/locale/th';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatabaseService from 'services/database';

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
  },
  root: {
    //  fontSize: 11,
    flexShrink: 0,
    marginLeft: theme.spacing(5),
    fontSize: '0.9rem',
  },
  paper: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  content: {
    display: 'none',
    padding: '5px 35px 0px 35px',
  },
  label: {
    fontWeight: 'bold',
    margin: 20,
  },
  table: {
    width: '100%',
    //  fontSize: 11,
    borderCollapse: 'collapse',
    fontSize: '0.9rem',
  },
  // tr: {
  //     backgroundColor: '#97C1E0 !important'
  // },
  [`@media print`]: {
    content: {
      display: 'block',
    },
    // tr: {
    //     backgroundColor: '#97C1E0 !important',
    // },
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

const MySwal = withReactContent(Swal);

const swal = html =>
  MySwal.fire({
    title: 'Please Confirm',
    html: html,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    reverseButtons: true,
  });

const PrintDeliveryNote = props => {
  const { setOpenDetail, invoices, setOpenDialog, printBy, setStatusPrinted } = props;
  const { authUser } = useSelector(({ auth }) => auth);
  const inputEl = useRef(null);
  const classes = useStyles();
  const [isPrinting, setIsPrinting] = useState(false);
  const [startPrint, setStartPrint] = useState(false);

  const handleClose = () => {
    setOpenDetail(false);
  };

  const handlePrint = () => {
    setIsPrinting(true);
    inputEl.current.window.print();
    setIsPrinting(false);
  };

  useEffect(() => {
    inputEl.current.window.onafterprint = () => {
      handleClose();
      if (setOpenDialog) setOpenDialog(false);

      if (printBy === 'DeliveryNote') {
        if (invoices[0].status === 'Created') {
          swal(`Confirm printing as successful and mark <br/> Delivery Note number
                     ${invoices[0].delivery_note_no} <br/> as Printed?`).then(result => {
            if (result.value) {
              DatabaseService.updateDeliveryNoteStatus({
                id: invoices[0].id,
                status: 'Printed',
                update_status_by: authUser.email,
                update_status_at: new Date(),
              })
                .then(() => {
                  NotificationManager.success('Status has been updated', 'Success');
                  if (setStatusPrinted) setStatusPrinted();
                  // if (setCustomStatus) setCustomStatus('Printed');
                })
                .catch(err => {
                  NotificationManager.error(err, 'Fail');
                });
            }
          });
        }
      } else if (printBy === 'Selected') {
        swal(`Confirm printing as successful and mark <br/> all selected delivery note number <br/> as Printed?`).then(
          result => {
            if (result.value) {
              invoices.map(item => {
                if (item.status === 'Created')
                  DatabaseService.updateDeliveryNoteStatus({
                    id: item.id,
                    status: 'Printed',
                    update_status_by: authUser.email,
                    update_status_at: new Date(),
                  })
                    .then(() => {
                      NotificationManager.success('Status has been updated', 'Success');
                    })
                    .catch(err => {
                      NotificationManager.error(err, 'Fail');
                    });
              });
            }
          },
        );
      } else {
        swal(`Confirm printing as successful and mark <br/> Delivery Note container <br/>
                 ${invoices[0].container} <br/> as Printed?`).then(result => {
          if (result.value) {
            invoices.map(item => {
              if (item.status === 'Created')
                DatabaseService.updateDeliveryNoteStatus({
                  id: item.id,
                  status: 'Printed',
                  update_status_by: authUser.email,
                  update_status_at: new Date(),
                })
                  .then(() => {
                    NotificationManager.success('Status has been updated', 'Success');
                  })
                  .catch(err => {
                    NotificationManager.error(err, 'Fail');
                  });
            });
          }
        });
      }
    };
  }, [inputEl]);

  useEffect(() => {
    setTimeout(() => {
      setStartPrint(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (startPrint) {
      handlePrint();
    }
  }, [startPrint]);

  return (
    <NewWindow ref={inputEl} features={{ width: 1920, height: 1080 }} title="Print Delivery Note">
      <div className={classes.content}>
        <Grid item xs={12}>
          <Box>
            {invoices.map((row, index) => (
              <div key={row.delivery_note_no}>
                <div className={classes.root}>
                  <br />
                  <div style={{ textAlign: 'right' }}>
                    <h1>ใบส่งของ</h1>
                  </div>
                </div>

                <hr style={{ margin: '-3px 0px 25px 0px' }} />

                <div className={classes.root}>
                  <Grid container spacing={5}>
                    <Grid item xs={6} sm={6}>
                      <strong>ลูกค้า:</strong>
                      <span> {row.customer_id}</span>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <strong>วันที่:</strong>
                      <span>
                        {' '}
                        {moment(row.delivery_note_date.toDate())
                          .locale('th')
                          .add(543, 'year')
                          .format('LL')}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5}>
                    <Grid item xs={6} sm={6}>
                      <strong>Sub:</strong>
                      <span> {row.sub ?? ''}</span>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <strong>เลขที่:</strong>
                      <span> {row.delivery_note_no}</span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} sm={6}>
                      <strong>ที่อยู่:</strong>
                      <span> {row.company_address}</span>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <strong>อ้างอิง:</strong>
                      <span> {row.container}</span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} sm={6}>
                      <strong>โทรศัพท์:</strong>
                      <span> {row.mobile}</span>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <strong>ผู้ส่งสินค้า:</strong>
                      <span> {row.driver}</span>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} sm={6}></Grid>

                    <Grid item xs={6} sm={6}>
                      <strong>ผู้สร้างรายการ:</strong>
                      <span> {row.created_by}</span>
                    </Grid>
                  </Grid>
                </div>

                <div style={{ marginTop: 20 }}>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead>
                          <tr className={classes.tr}>
                            <th className={classes.th}>Date</th>
                            <th className={classes.th}>Bill No.</th>
                            <th className={classes.th}>Sub</th>
                            <th className={classes.th}>Description</th>
                            <th className={classes.th}>Pack</th>
                            <th className={classes.th} style={{ textAlign: 'center' }}>
                              Weight <p>(kgs)</p>
                            </th>
                            <th className={classes.th} style={{ textAlign: 'center' }}>
                              Length <p>(CM)</p>
                            </th>
                            <th className={classes.th} style={{ textAlign: 'center' }}>
                              Width <p>(CM)</p>
                            </th>
                            <th className={classes.th} style={{ textAlign: 'center' }}>
                              Height <p>(CM)</p>
                            </th>
                            <th className={classes.th} style={{ textAlign: 'center' }}>
                              CBM
                            </th>
                            <th className={classes.th} style={{ textAlign: 'center' }}>
                              Type
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {row.productNumberList?.map(item => (
                            <tr key={item.po_number}>
                              <td className={classes.td}>
                                {moment(new Date((item.date.seconds + item.date.nanoseconds * 10 ** -9) * 1000)).format(
                                  'DD/MM/YYYY',
                                )}
                              </td>
                              <td className={classes.td}>{item.po_number}</td>
                              <td className={classes.td}>{item.sub_customer}</td>
                              <td className={classes.td}>{item.description}</td>
                              <td className={classes.td}>{NumberWithCommas(item.packs)}</td>
                              <td className={classes.td}>{NumberWithCommas(item.weight.toFixed(2))}</td>
                              <td className={classes.td}>{NumberWithCommas(item.length.toFixed(2))}</td>
                              <td className={classes.td}>{NumberWithCommas(item.width.toFixed(2))}</td>
                              <td className={classes.td}>{NumberWithCommas(item.height.toFixed(2))}</td>
                              <td className={classes.td}>{item.cbm.toFixed(4)}</td>
                              <td className={classes.td}>{item.productType || item.type}</td>
                            </tr>
                          ))}
                          <tr>
                            <td className={classes.td}></td>
                            <td className={classes.td}>Grand Total</td>
                            <td className={classes.td}></td>
                            <td className={classes.td}></td>
                            <td className={classes.td}>{NumberWithCommas(row.total_pack.toFixed(2))}</td>
                            <td className={classes.td}>{NumberWithCommas(row.total_weight.toFixed(2))}</td>
                            <td className={classes.td}></td>
                            <td className={classes.td}></td>
                            <td className={classes.td}></td>
                            <td className={classes.td}>{row.total_cbm.toFixed(4)}</td>
                            <td className={classes.td}></td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                </div>

                <div id="break-content">
                  <div className={classes.root}>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={12}>
                        <strong>หมายเหตุ</strong> <span>ทั้งหมด {NumberWithCommas(row.total_pack)} Unit </span>
                        <p style={{ marginTop: 10, marginLeft: 67 }}>
                          กรุณาตรวจสอบสินค้าหลังจากได้รับสินค้า หากพบปัญหาให้แจ้งบริษัทฯรับทราบภายใน 3 วัน
                          มิฉะนั้นจะไม่รับผิดชอบใดๆ ทั้งสิ้น{' '}
                        </p>
                        <p style={{ marginLeft: 67 }}>หากพบปัญหาใดๆ รบกวนลูกค้าโอนค่าขนส่งเข้าบัญชีที่บริษัทฯกำหนดไว้ </p>
                        <p style={{ marginTop: 10, marginLeft: 67 }}>***ห้ามจ่ายค่าขนส่งกับพนักงานขนส่ง***</p>
                      </Grid>
                    </Grid>
                  </div>

                  <hr style={{ margin: '25px 0px 25px 0px' }} />

                  <div className={classes.root}>
                    <Grid container spacing={5}>
                      <Grid item xs={4} sm={4}>
                        <strong>ผู้รับสินค้า</strong>
                        <hr style={{ marginTop: 50, border: '1px dashed' }} />
                        <p style={{ marginTop: 15 }}>วันที่</p>
                        <hr style={{ border: '1px dashed' }} />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <strong>ผู้ส่งสินค้า</strong>
                        <hr style={{ marginTop: 50, border: '1px dashed' }} />
                        <p style={{ marginTop: 15 }}>วันที่</p>
                        <hr style={{ border: '1px dashed' }} />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <strong>ผู้อนุมัติ</strong>
                        <hr style={{ marginTop: 50, border: '1px dashed' }} />
                        <p style={{ marginTop: 15 }}>วันที่</p>
                        <hr style={{ border: '1px dashed' }} />
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div className="pagebreak"></div>
              </div>
            ))}
            {!isPrinting && (
              <div className={classes.root} style={{ marginTop: 35 }}>
                <Box component="span" displayPrint="none">
                  <Grid container justify="center">
                    <Button variant="contained" onClick={handleClose}>
                      Go Back
                    </Button>
                    <Button style={{ marginLeft: 10 }} color="primary" variant="contained" onClick={handlePrint}>
                      Print
                    </Button>
                  </Grid>
                </Box>
              </div>
            )}
          </Box>
        </Grid>
      </div>
    </NewWindow>
  );
};

export default PrintDeliveryNote;
