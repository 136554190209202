import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
// import './css/action.css';
import DeliveryNoteDetail from './detail';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 100,
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#97C1E0 !important',
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

// const MySwal = withReactContent(Swal);

const Action = ({ open, setOpenAction, selectedInvoice, selectedRow, typeAction, showBy }) => {
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpenAction(false);
  };

  return (
    <Dialog fullScreen open={open} onClose={handleCloseDialog} className={classes.dialogRoot}>
      <Box component="span" displayPrint="none">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
              <CloseIcon />
            </IconButton>

            <Typography variant="h2">{`Detail of Delivery Note: ${selectedRow.delivery_note_no}`}</Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <DeliveryNoteDetail open={open} setOpenAction={setOpenAction} selectedRow={selectedRow} typeAction={typeAction} />
    </Dialog>
  );
};

export default Action;
