import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Home from './Pages/HomePage';
import ImportArrivedWHChina from './Pages/Import/ImportArrivedWHChina';
import Registration from './Pages/Management/Registration';
import User from './Pages/Management/User';
import Customer from './Pages/Management/Customer';
import SubManagement from './Pages/Management/Sub';
import ImportClosedContainer from './Pages/Import/ImportClosedContainer';
import { AuhMethods } from '../services/auth';
import { CurrentAuthMethod } from '../@jumbo/constants/AppConstants';
import OrderDetail from './Pages/HomePage/detail';
import UploadImages from './Pages/Import/UploadImages';
import EditOrder from './Pages/HomePage/edit';
import UpdateArrivedWhThai from './Pages/Update/UpdateArrivedWhThai';
import UpdateDelivering from './Pages/Update/UpdateDelivering';
import UpdateDelivered from './Pages/Update/UpdateDelivered';
import UpdateRemark from './Pages/Update/UpdateRemark';
import BillingBill from './Pages/Billing/Bill';
import BillingDelivery from './Pages/Billing/Delivery';
import PrintByContainerDialog from './Pages/Billing/Delivery/Dialog/printByContainerDialog';
import CreateDeliveryNoteByContainer from './Pages/Billing/Delivery/Dialog/createDeliveryNoteByContainer';
import ReceivableBalance from './Pages/Report/ReceivableBalance';
import CollectionDaily from './Pages/Report/CollectionDaily';
import UpdateRemarked from './Pages/Update/UpdateRemarked';
// import BillDetail from './Pages/Billing/Bill/detail';
import InvoiceDetail from './Pages/Billing/Bill/Action/newpage';
// import DeliveryNoteDetail from './Pages/Billing/Delivery/detail';
import DriverManagement from './Pages/Management/Driver';
import DeliveryNoteNewPage from './Pages/Billing/Delivery/Action/newpage';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const intersection = authUser && authUser.type && rest.roles.includes(authUser.type);

  const onLogoutClick = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  if (!intersection) {
    onLogoutClick();
  }

  return (
    <Route
      {...rest}
      render={props =>
        !authUser ? (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        ) : !intersection ? (
          <Redirect
            to={{
              pathname: '/unauthorize',
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  // if (location.pathname === '' || location.pathname === '/') {
  //   return <Redirect to={'/home'} />;
  // } else if (authUser && location.pathname === '/signin') {
  //   return <Redirect to={'/home'} />;
  // }

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/home'} />;
  } else if (!authUser && location.pathname !== '/signin' && location.pathname !== '/') {
    return <Redirect to={'/signin'} />;
  } else if (location.pathname === '/signin' && authUser && authUser.email) {
    return <Redirect to={'/home'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute
          path="/home"
          roles={['customer', 'driver', 'admin', 'superadmin', 'frontadmin', 'delivery', 'accountant']}
          component={Home}
          exact
        />
        <RestrictedRoute
          path="/home/detail/:id"
          roles={['driver', 'customer', 'admin', 'superadmin']}
          component={OrderDetail}
        />
        <RestrictedRoute path="/home/edit/:id" roles={['superadmin']} component={EditOrder} />
        <RestrictedRoute
          path="/import-arrived-wh-china"
          roles={['delivery', 'admin', 'superadmin']}
          component={ImportArrivedWHChina}
        />
        <RestrictedRoute
          path="/import-closed-container"
          roles={['delivery', 'admin', 'superadmin']}
          component={ImportClosedContainer}
        />
        <RestrictedRoute
          path="/update-arrived-wh-thai"
          roles={['delivery', 'admin', 'superadmin']}
          component={UpdateArrivedWhThai}
        />
        <RestrictedRoute path="/update-delivering" roles={['admin', 'superadmin']} component={UpdateDelivering} />
        <RestrictedRoute path="/update-delivered" roles={['admin', 'superadmin']} component={UpdateDelivered} />
        <RestrictedRoute
          path="/update-remark"
          roles={['frontadmin', 'delivery', 'admin', 'superadmin']}
          component={UpdateRemark}
        />
        <RestrictedRoute
          path="/update-remarked"
          roles={['frontadmin', 'delivery', 'admin', 'superadmin']}
          component={UpdateRemarked}
        />
        <RestrictedRoute path="/upload-images" roles={['delivery', 'admin', 'superadmin']} component={UploadImages} />
        <RestrictedRoute path="/user-management" roles={['admin', 'superadmin']} component={User} />
        <RestrictedRoute path="/customer-management" roles={['admin', 'superadmin']} component={Customer} />
        <RestrictedRoute path="/sub-management" roles={['admin', 'superadmin']} component={SubManagement} />
        <RestrictedRoute
          path="/driver-management"
          roles={['delivery', 'admin', 'superadmin']}
          component={DriverManagement}
        />
        <RestrictedRoute path="/registration-management" roles={['admin', 'superadmin']} component={Registration} />
        <RestrictedRoute
          path="/billing-bill"
          roles={['frontadmin', 'accountant', 'delivery', 'superadmin']}
          component={BillingBill}
          exact
        />
        <RestrictedRoute
          path="/billing-bill/no/:id"
          roles={['frontadmin', 'accountant', 'delivery', 'superadmin']}
          component={InvoiceDetail}
        />
        <RestrictedRoute path="/billing-delivery" roles={['delivery', 'superadmin']} component={BillingDelivery} exact />
        <RestrictedRoute
          path="/billing-delivery/no/:id"
          roles={['delivery', 'superadmin']}
          component={DeliveryNoteNewPage}
        />
        <RestrictedRoute path="/print-by-container" roles={['delivery', 'superadmin']} component={PrintByContainerDialog} />
        <RestrictedRoute
          path="/billing-delivery/create-delivery-note-by-container"
          roles={['delivery', 'superadmin']}
          component={CreateDeliveryNoteByContainer}
        />
        <RestrictedRoute path="/receivable-balance" roles={['superadmin']} component={ReceivableBalance} />
        <RestrictedRoute path="/collection-daily-report" roles={['superadmin']} component={CollectionDaily} exact />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
