import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import Api from '../../../../services/api.service';
import DatabaseService from '../../../../services/database';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import Api from '../../../../services/api.service';
import { EditButton, DeleteButton } from '../../../../components/ColorButton';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
}));

const SignupSchema = yup.object().shape({
  email: yup
    .string()
    .required('E-mail is required')
    .email('Invalid email address'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  name: yup.string().required('Name is required'),
  mobile: yup.string().required('Phone Number is required'),
});

const defaultValues = {
  name: '',
  mobile: '',
  licensePlate: '',
  email: '',
  password: '',
  line_id: '',
  company: '',
  company_address: '',
};

const Approval = ({ open, handleDialog, selectedUser, typeAction }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues,
  });

  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const { customers } = useSelector(({ master }) => master);

  const handleCloseDialog = () => {
    handleDialog();
    reset();
  };

  const onSubmit = data => {
    setPending(true);
    Api.approveDriver(data)
      .then(() => {
        handleDialog();
        NotificationManager.success('User has been approved', 'Success');
        setPending(false);
      })
      .catch(err => {
        NotificationManager.error(err, 'Error');
      });
  };

  const fetchRowData = async () => {
    const row = selectedUser;
    reset({
      id: row.id,
      name: row.name,
      mobile: row.mobile,
      license_plate: row.license_plate,
      email: '',
      password: '',
      line_id: '',
      company: '',
      company_address: '',
    });
  };

  useEffect(() => {
    if (selectedUser) fetchRowData();
  }, []);

  return (
    <Dialog open={open} onClose={handleCloseDialog} className={classes.dialogRoot} maxWidth={'md'} fullWidth>
      <DialogTitle className={classes.dialogTitleRoot}>Approve Driver</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Driver Name"
                      variant="outlined"
                      error={errors.name && true}
                      helperText={errors.name?.message}
                      disabled
                      fullWidth
                    />
                  )}
                  name="name"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Mobile Phone"
                      variant="outlined"
                      error={errors.mobile && true}
                      helperText={errors.mobile?.message}
                      fullWidth
                    />
                  )}
                  name="mobile"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="License plate"
                      variant="outlined"
                      error={errors.licensePlate && true}
                      helperText={errors.licensePlate?.message}
                      fullWidth
                    />
                  )}
                  name="license_plate"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      variant="outlined"
                      error={errors.email && true}
                      helperText={errors.email?.message}
                      fullWidth
                    />
                  )}
                  name="email"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      variant="outlined"
                      type="password"
                      fullWidth
                      error={errors.password && true}
                      helperText={errors.password?.message}
                    />
                  )}
                  name="password"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => <TextField {...field} label="Line Id" variant="outlined" fullWidth />}
                  name="line_id"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => <TextField {...field} label="Company" fullWidth variant="outlined" />}
                  name="company"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField {...field} multiline rows={4} label="Address" fullWidth variant="outlined" />
                  )}
                  name="company_address"
                  control={control}
                />
              </Grid>
            </GridContainer>
          </Box>

          {pending && <LinearProgress color="secondary" className={classes.linear} />}
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={handleDialog} disabled={pending}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button type="submit" variant="contained" color="primary" disabled={pending}>
                Approve
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Approval;
