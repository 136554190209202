import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import Api from '../../../../services/api.service';
import NumberFormatCustom from '../../../../helpers/NumberFormatCustom';
import DatabaseService from '../../../../services/database';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditButton, DeleteButton } from '../../../../components/ColorButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
}));

const SignupSchema = yup.object().shape({
  name: yup.string().required('Cusomter name is required'),
  customer_price: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Type is required'),
      warehouse: yup.string().required('Warehouse is required '),
    }),
  ),
});

const defaultValues = {
  name: '',
  company_address: '',
  mobile: '',
};

const Action = ({ open, handleDialog, selectedUser, typeAction }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues,
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'customer_price',
  });

  const dispatch = useDispatch();
  const classes = useStyles();
  const [isCustomer, setIsCustomer] = useState(false);
  const [pending, setPending] = useState(false);
  const typeDetail = typeAction === 'detail';
  const typeEdit = typeAction === 'edit';
  const typeDelete = typeAction === 'delete';

  const handleCloseDialog = () => {
    handleDialog();
    reset();
  };

  const onSubmit = data => {
    setPending(true);
    data.name = data.name.toUpperCase();

    if (typeEdit) {
      DatabaseService.updateCustomer(selectedUser.id, data)
        .then(() => {
          handleDialog();
          NotificationManager.success('Customer has been updated', 'Success');
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    } else if (typeDelete) {
      DatabaseService.deleteCustomer(selectedUser)
        .then(() => {
          handleDialog();
          NotificationManager.success('Customer has been deleted', 'Success');
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    } else {
      DatabaseService.addCustmer(data)
        .then(() => {
          handleDialog();
          NotificationManager.success('Customer has been created', 'Success');
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    }
  };

  const fetchRowData = async () => {
    const row = selectedUser;
    reset({
      name: row.name,
      company_address: row.company_address,
      mobile: row.mobile,
      customer_price: row.customer_price ?? [],
    });
  };

  const handleLoadDefault = () => {
    append([
      { type: 'N', warehouse: 'กวางโจว', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'N', warehouse: 'อิอู', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'M', warehouse: 'กวางโจว', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'M', warehouse: 'อิอู', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'CC', warehouse: 'กวางโจว', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'CC', warehouse: 'อิอู', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'AB', warehouse: 'กวางโจว', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'AB', warehouse: 'อิอู', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'BB', warehouse: 'กวางโจว', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'BB', warehouse: 'อิอู', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'MB', warehouse: 'กวางโจว', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
      { type: 'MB', warehouse: 'อิอู', kg_ek_price: 3000, kg_sea_price: 3000, m3_ek_price: 5000, m3_sea_price: 5000 },
    ]);
  };

  useEffect(() => {
    if (selectedUser) fetchRowData();
  }, []);

  return (
    <Dialog open={open} onClose={handleCloseDialog} className={classes.dialogRoot} maxWidth={'md'} fullWidth>
      <DialogTitle className={classes.dialogTitleRoot}>
        {typeEdit
          ? 'Edit Customer'
          : typeDelete
          ? 'Delete Customer'
          : typeDetail
          ? 'Detail of Customer'
          : 'Create New Customer'}
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name"
                      variant="outlined"
                      error={errors.name && true}
                      helperText={errors.name?.message}
                      disabled={typeDetail || typeDelete}
                      fullWidth
                    />
                  )}
                  name="name"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Address"
                      variant="outlined"
                      error={errors.company_address && true}
                      helperText={errors.company_address?.message}
                      disabled={typeDetail || typeDelete}
                      fullWidth
                    />
                  )}
                  name="company_address"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Mobile Phone"
                      variant="outlined"
                      error={errors.mobile && true}
                      helperText={errors.mobile?.message}
                      fullWidth
                      disabled={typeDetail || typeDelete}
                    />
                  )}
                  name="mobile"
                  control={control}
                />
              </Grid>

              <Grid item xs={9} sm={9}>
                <h3>Customer Price</h3>
              </Grid>

              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: '#E6E6E6' }}>
                    <TableRow>
                      <TableCell width={150}>Type</TableCell>
                      <TableCell width={200}>Warehouse</TableCell>
                      <TableCell width={150}>Kg EK</TableCell>
                      <TableCell width={150}>Kg SEA</TableCell>
                      <TableCell width={150}>M3 EK</TableCell>
                      <TableCell width={150}>M3 SEA</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => append({})}
                          disabled={typeDetail || typeDelete}>
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.length === 0 ? (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={7}>
                          <div style={{ textAlign: 'center' }}>
                            <span>
                              {typeDetail || typeDelete ? (
                                <div>No Data Available in Table</div>
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleLoadDefault}
                                    disabled={typeDelete}>
                                    Load Default
                                  </Button>{' '}
                                  or{' '}
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => append({})}
                                    disabled={typeDelete}>
                                    Add
                                  </Button>
                                </>
                              )}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      fields.map((field, index) => (
                        <TableRow key={field.id}>
                          <TableCell component="th" scope="row">
                            <Controller
                              control={control}
                              name={`customer_price[${index}].type`}
                              defaultValue={field.type || ''}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  onChange={(event, item) => {
                                    onChange(item?.name);
                                  }}
                                  value={TYPE.find(v => v.name === value) || {}}
                                  options={TYPE}
                                  getOptionLabel={item => item.name ?? ''}
                                  disabled={typeDetail || typeDelete}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      error={errors.customer_price?.[index]?.type && true}
                                      helperText={errors.customer_price?.[index]?.type?.message}
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Controller
                              control={control}
                              name={`customer_price[${index}].warehouse`}
                              defaultValue={field.warehouse || ''}
                              render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                  onChange={(event, item) => {
                                    onChange(item?.name);
                                  }}
                                  value={WAREHOUSE.find(v => v.name === value) || {}}
                                  options={WAREHOUSE}
                                  getOptionLabel={item => item.name ?? ''}
                                  disabled={typeDetail || typeDelete}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      error={errors.customer_price?.[index]?.warehouse && true}
                                      helperText={errors.customer_price?.[index]?.warehouse?.message}
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  fullWidth
                                  disabled={typeDetail || typeDelete}
                                />
                              )}
                              name={`customer_price[${index}].kg_ek_price`}
                              control={control}
                              defaultValue={field.kg_ek_price || 0}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  fullWidth
                                  disabled={typeDetail || typeDelete}
                                />
                              )}
                              name={`customer_price[${index}].kg_sea_price`}
                              control={control}
                              defaultValue={field.kg_sea_price || 0}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  fullWidth
                                  disabled={typeDetail || typeDelete}
                                />
                              )}
                              name={`customer_price[${index}].m3_ek_price`}
                              control={control}
                              defaultValue={field.m3_ek_price || 0}
                            />
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  fullWidth
                                  disabled={typeDetail || typeDelete}
                                />
                              )}
                              name={`customer_price[${index}].m3_sea_price`}
                              control={control}
                              defaultValue={field.m3_sea_price || 0}
                            />
                          </TableCell>

                          <TableCell align="left">
                            <Button variant="contained" onClick={() => remove(index)} disabled={typeDetail || typeDelete}>
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridContainer>
          </Box>

          {pending && <LinearProgress color="secondary" className={classes.linear} />}
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={handleDialog} disabled={pending}>
              Cancel
            </Button>
            <Box ml={2}>
              {typeEdit && (
                <EditButton type="submit" variant="contained" color="primary" disabled={pending}>
                  Update
                </EditButton>
              )}
              {typeDelete && (
                <DeleteButton type="submit" variant="contained" color="primary" disabled={pending}>
                  Delete
                </DeleteButton>
              )}
              {!typeDetail && !typeEdit && !typeDelete && (
                <Button type="submit" variant="contained" color="primary" disabled={pending}>
                  Create
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Action;

const TYPE = [{ name: 'N' }, { name: 'M' }, { name: 'CC' }, { name: 'AB' }, { name: 'BB' }, { name: 'MB' }];
const WAREHOUSE = [{ name: 'กวางโจว' }, { name: 'อิอู' }];
// const TRANSPORTATION_TYPE = [{name: }, {}]
