import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Action from './action';
import Button from '@material-ui/core/Button';
import { Add, AssignmentTurnedIn, Edit, Delete, NavigateNext, NavigateBefore } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import DatabaseService from '../../../../services/database';
import memoize from 'memoize-one';
import { firestore } from '../../../../firebase';
import Api from 'services/api.service';
import { usePagination } from 'use-pagination-firestore';
import moment from 'moment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { fetchFilter, fetchSearch } from 'redux/actions/Search';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Sample Page', isActive: true },
];

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#EFEFEF',
    },
  },
};

const columnsContainer = memoize(handleEdit => [
  {
    cell: row => <Edit onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    name: 'Container',
    selector: 'container',
    sortable: true,
  },
  // {
  //     name: 'Date',
  //     selector: 'date',
  //     sortable: true,
  //     cell: row => moment(row.date.toDate()).format("DD/MM/YYYY"),
  //     width: '120px',
  // },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
]);

const columns = memoize(handleEdit => [
  {
    cell: row => <Edit onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    name: 'Product Number',
    selector: 'po_number',
    sortable: true,
  },
  // {
  //     name: 'Item Code',
  //     selector: 'item_code',
  //     sortable: true,
  //     width: '150px',
  // },
  {
    name: 'Container',
    selector: 'container',
    sortable: true,
    width: '360px',
  },
  {
    name: 'Tracking Number',
    selector: 'tracking_number',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Pack',
    selector: 'packs',
    sortable: true,
  },
  {
    name: 'CBM',
    selector: 'cbm',
    sortable: true,
    cell: row => row.cbm.toFixed(4),
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    cell: row => moment(row.date.toDate()).format('DD/MM/YYYY'),
    width: '120px',
  },
  {
    name: 'Customer',
    selector: 'customer_id',
    sortable: true,
    width: '120px',
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
  },
]);

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <LinearProgress />
    </div>
  );
};

const UpdateArrivedWhThai = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const { authUser } = useSelector(({ auth }) => auth);
  const { searchText } = useSelector(({ search }) => search);
  const { filterSearch } = useSelector(({ search }) => search);
  const [pending, setPending] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [typeAction, setTypeAction] = useState();
  const [showBy, setShowBy] = useState('container');

  const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
    DatabaseService.fetchOrdersData({
      authUser: authUser,
      status: 'Closed Container',
      filterSearch: filterSearch,
      searchText: searchText,
    }),
    // firestore.collection("/orders").where('status', '==', 'Closed Container').orderBy("po_number", "desc"),
    {
      limit: 20,
    },
  );

  const handleDialog = () => {
    setTypeAction();
    setSelectedUser();
    setOpen(false);
  };

  const handleEdit = row => {
    setTypeAction('edit');
    setSelectedUser(row);
    setOpen(true);
  };

  const handleShowBy = type => {
    if (type === 'container') {
      dispatch(fetchFilter([{ key: 'container', name: 'Container' }]));
    } else {
      dispatch(
        fetchFilter([
          { key: 'po_number', name: 'Product Number' },
          { key: 'container', name: 'Container' },
          { key: 'tracking_number', name: 'Tracking Number' },
          { key: 'customer_id', name: 'Customer' },
        ]),
      );
    }

    setShowBy(type);
  };

  const fecthData = () => {
    const docRef = firestore.collection('orders');
    const query = docRef.where('status', '==', 'Closed Container');

    query.onSnapshot(snapShot => {
      let tempDataArray = [];
      snapShot.forEach(doc => {
        if (doc.exists) {
          let data = doc.data();
          data.id = doc.id;

          let obj = tempDataArray.find(o => o.container === data.container);
          if (!obj) {
            tempDataArray = [...tempDataArray, data];
          }
        }
      });
      setData(() => tempDataArray);
    });
  };

  useEffect(() => {
    if (showBy === 'container') fecthData();

    dispatch(fetchSearch(''));
    dispatch(fetchFilter([{ key: 'container', name: 'Container' }]));
  }, []);

  return (
    <GridContainer>
      <Grid item xs={4} container justify="flex-start">
        .
        <Typography variant="h2">
          <IntlMessages id="page.updateArrivedWhThai" />
        </Typography>
      </Grid>
      <Grid item xs={1} />

      <Grid item xs={7} container justify="flex-end">
        <ButtonGroup aria-label="outlined primary button group">
          <Button
            onClick={() => handleShowBy('container')}
            style={{
              backgroundColor: showBy === 'container' && '#03DAC5',
              color: showBy !== 'productNumber' && '#fff',
            }}>
            By Container
          </Button>
          <Button
            onClick={() => handleShowBy('productNumber')}
            style={{
              backgroundColor: showBy === 'productNumber' && '#03DAC5',
              color: showBy !== 'container' && '#fff',
            }}>
            By Product Number
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Card className={classes.card}>
            <DataTable
              noHeader
              title="Update Arrived WH Thai"
              columns={showBy === 'container' ? columnsContainer(handleEdit) : columns(handleEdit)}
              data={
                showBy === 'container'
                  ? searchText
                    ? data.filter(x => x.container.toUpperCase().indexOf(searchText.toUpperCase()) > -1)
                    : data
                  : items
              }
              // selectableRows
              progressPending={isLoading}
              progressComponent={<LinearIndeterminate />}
              customStyles={customStyles}
            />
            {open && (
              <Action
                open={open}
                handleDialog={handleDialog}
                selectedUser={selectedUser}
                typeAction={typeAction}
                showBy={showBy}
              />
            )}
          </Card>
          {showBy !== 'container' && (
            <Grid container justify="flex-end" spacing={5} style={{ marginTop: 5 }}>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={getPrev} disabled={showBy === 'container' || isStart}>
                  <NavigateBefore /> Previous
                </Button>
              </Grid>

              <Grid item>
                <Button variant="contained" color="secondary" onClick={getNext} disabled={showBy === 'container' || isEnd}>
                  Next <NavigateNext />
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </GridContainer>
  );
};

export default UpdateArrivedWhThai;
