import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import Api from '../../../../services/api.service';
import DatabaseService from '../../../../services/database';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditButton, DeleteButton } from '../../../../components/ColorButton';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
}));

const SignupSchema = yup.object().shape({
  customer_id: yup.string().required('Customer Id is required'),
  name: yup.string().required('Sub name is required'),
});

const defaultValues = {
  name: '',
  company_address: '',
  mobile: '',
};

const Action = ({ open, handleDialog, selectedUser, typeAction }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues,
  });

  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const { customers } = useSelector(({ master }) => master);
  const typeDetail = typeAction === 'detail';
  const typeEdit = typeAction === 'edit';
  const typeDelete = typeAction === 'delete';

  const handleCloseDialog = () => {
    handleDialog();
    reset();
  };

  const onSubmit = data => {
    setPending(true);
    data.name = data.name.toUpperCase();

    if (typeEdit) {
      DatabaseService.updateSub(selectedUser.id, data)
        .then(() => {
          handleDialog();
          NotificationManager.success('Sub has been updated', 'Success');
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    } else if (typeDelete) {
      DatabaseService.deleteSub(selectedUser)
        .then(() => {
          handleDialog();
          NotificationManager.success('Sub has been deleted', 'Success');
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    } else {
      DatabaseService.addSub(data)
        .then(() => {
          handleDialog();
          NotificationManager.success('Sub has been created', 'Success');
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    }
  };

  const fetchRowData = async () => {
    const row = selectedUser;
    reset({
      customer_id: row.customer_id,
      name: row.name,
      company_address: row.company_address,
      mobile: row.mobile,
    });
  };

  useEffect(() => {
    if (selectedUser) fetchRowData();
  }, []);

  return (
    <Dialog open={open} onClose={handleCloseDialog} className={classes.dialogRoot} maxWidth={'md'} fullWidth>
      <DialogTitle className={classes.dialogTitleRoot}>
        {typeEdit ? 'Edit Sub' : typeDelete ? 'Delete Sub' : typeDetail ? 'Detail of Sub' : 'Create New Sub'}
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <Controller
                  control={control}
                  name="customer_id"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item?.name);
                      }}
                      value={customers.find(v => v.name.toString() === value) || {}}
                      options={customers}
                      getOptionLabel={item => item.name?.toString() ?? ''}
                      disabled={typeDetail || typeDelete}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Customer Id"
                          margin="normal"
                          variant="outlined"
                          error={errors.customer_id && true}
                          helperText={errors.customer_id?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Sub Name"
                      variant="outlined"
                      error={errors.name && true}
                      helperText={errors.name?.message}
                      disabled={typeDetail || typeDelete}
                      fullWidth
                    />
                  )}
                  name="name"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Address"
                      variant="outlined"
                      error={errors.company_address && true}
                      helperText={errors.company_address?.message}
                      disabled={typeDetail || typeDelete}
                      fullWidth
                    />
                  )}
                  name="company_address"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Mobile Phone"
                      variant="outlined"
                      error={errors.mobile && true}
                      helperText={errors.mobile?.message}
                      fullWidth
                      disabled={typeDetail || typeDelete}
                    />
                  )}
                  name="mobile"
                  control={control}
                />
              </Grid>
            </GridContainer>
          </Box>

          {pending && <LinearProgress color="secondary" className={classes.linear} />}
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={handleDialog} disabled={pending}>
              Cancel
            </Button>
            <Box ml={2}>
              {typeEdit && (
                <EditButton type="submit" variant="contained" color="primary" disabled={pending}>
                  Update
                </EditButton>
              )}
              {typeDelete && (
                <DeleteButton type="submit" variant="contained" color="primary" disabled={pending}>
                  Delete
                </DeleteButton>
              )}
              {!typeDetail && !typeEdit && !typeDelete && (
                <Button type="submit" variant="contained" color="primary" disabled={pending}>
                  Create
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Action;

const TYPE = [{ name: 'N' }, { name: 'M' }, { name: 'CC' }, { name: 'AB' }, { name: 'BB' }, { name: 'MB' }];
const WAREHOUSE = [{ name: 'กวางโจว' }, { name: 'อิอู' }];
// const TRANSPORTATION_TYPE = [{name: }, {}]
