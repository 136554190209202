import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { firestore, storage } from '../../../../../firebase';
import Api from '../../../../../services/api.service';
import DatabaseService from '../../../../../services/database';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditButton, DeleteButton } from '../../../../../components/ColorButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import NumberWithCommas from 'helpers/NumberWithCommas';
import billStatus from 'helpers/BillStatus';
import { Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PrintInvoice from '../Print/print';
import ModalImage from 'react-modal-image';
import Paper from '@material-ui/core/Paper';
import '../css/action.css';
import RBAC from '../../../../../helpers/Rbac';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 100,
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#97C1E0 !important',
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

const MySwal = withReactContent(Swal);

const Action = ({ open, setOpenAction, selectedRow, typeAction, showBy, typePage }) => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [openDetail, setOpenDetail] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [row, setRow] = useState(selectedRow);
  // const row = selectedRow;
  // const [customStatus, setCustomStatus] = useState(row.status);
  const [imgUrls, setImgUrls] = useState([]);
  const [form, setForm] = useState({
    remarkCustomer: row.remark_customer || '',
    remarkInternal: row.remark_internal || '',
    status: row.status,
  });

  const handleCloseDialog = () => {
    setOpenAction(false);
  };

  const handleUpdateStatus = status => {
    MySwal.fire({
      title: 'Please Confirm',
      text: `Comfirm to change status to ${status}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        DatabaseService.updateInvoiceStatus({
          id: row.id,
          status: status,
          update_status_by: authUser.email,
          update_status_at: new Date(),
          form: form,
        })
          .then(() => {
            setRow({ ...row, status: status });
            setForm({ ...form, status: status });
            NotificationManager.success('Status has been updated', 'Success');
          })
          .catch(err => {
            NotificationManager.error(err, 'Fail');
          });
      }
    });
  };

  const handleUploadPaySlip = async () => {
    Swal.fire({
      title: 'Select Pay Slip',
      text: 'Upload Pay Slip to change status to Uploaded',
      input: 'file',
      inputAttributes: {
        accept: 'image/*',
        multiple: 'multiple',
        'aria-label': 'Upload your profile picture',
      },
      showCancelButton: true,
      confirmButtonText: 'Upload',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      preConfirm: async files => {
        if (!files) {
          Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Pay Slip is required');
        } else {
          // let pictures = [];
          let idx = 0;
          row.pictures_path = row.pictures_path || [];

          for (let file of files) {
            let pictureName =
              row.pictures_path.length === 0 ? `${row.invoice_no}` : `${row.invoice_no}-${row.pictures_path.length + 1}`;
            await DatabaseService.uploadSlip({ file: file, pictureName: pictureName }).then(res => {
              row.pictures_path.push(res.metadata.fullPath);
              idx++;
            });
          }

          DatabaseService.updateInvoiceStatus({
            id: row.id,
            status: 'Uploaded',
            update_status_by: authUser.email,
            update_status_at: new Date(),
            picturesPath: row.pictures_path,
            form: form,
          })
            .then(() => {
              // handleCloseDialog();
              setRow({ ...row, status: 'Uploaded', pictures_path: row.pictures_path });
              setForm({ ...form, status: 'Uploaded' });
              NotificationManager.success('Status has been updated', 'Success');
            })
            .catch(err => {
              NotificationManager.error(err, 'Fail');
            });
        }
      },
    });
  };

  const handleAddPaySlip = async () => {
    Swal.fire({
      title: 'Select Pay Slip',
      text: 'Upload Pay Slip to change status to Uploaded',
      input: 'file',
      inputAttributes: {
        accept: 'image/*',
        multiple: 'multiple',
        'aria-label': 'Upload your profile picture',
      },
      showCancelButton: true,
      confirmButtonText: 'Upload',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      preConfirm: async files => {
        if (!files) {
          Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Pay Slip is required');
        } else {
          // let pictures = [];
          let idx = 0;
          row.pictures_path = row.pictures_path || [];

          for (let file of files) {
            let pictureName =
              row.pictures_path.length === 0 ? `${row.invoice_no}` : `${row.invoice_no}-${row.pictures_path.length + 1}`;
            await DatabaseService.uploadSlip({ file: file, pictureName: pictureName }).then(res => {
              row.pictures_path.push(res.metadata.fullPath);
              idx++;
            });
          }

          DatabaseService.addPaySlip({
            id: row.id,
            update_status_by: authUser.email,
            update_status_at: new Date(),
            picturesPath: row.pictures_path,
          })
            .then(res => {
              // handleCloseDialog();
              setRow({ ...row, pictures_path: row.pictures_path });
              // setForm({ ...form });
              NotificationManager.success('Pay Slip has been added', 'Success');
            })
            .catch(err => {
              NotificationManager.error(err, 'Fail');
            });
        }
      },
    });
  };

  const handleCancel = () => {
    handleCloseDialog();

    MySwal.fire({
      title: 'Please Confirm',
      html: `Comfirm to cancel Invoice Number <br/> ${row.invoice_no}? <br/>`,
      icon: 'warning',
      input: 'textarea',
      inputPlaceholder: 'Remark here...',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      preConfirm: value => {
        if (!value) {
          Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Remark is required');
        } else {
          DatabaseService.cancelInvoiceStatus({
            id: row.id,
            remark: value,
            cancel_by: authUser.email,
            cancel_at: new Date(),
          })
            .then(() => {
              setRow({ ...row, status: 'Cancel' });
              setForm({ ...form, status: 'Cancel' });
              NotificationManager.success('Delivery Note has been deleted', 'Success');
            })
            .catch(err => {
              NotificationManager.error(err, 'Fail');
            });
        }
      },
    });
  };

  const handleConfirm = () => {
    // handleCloseDialog();

    MySwal.fire({
      title: 'Please Confirm',
      html: `<div><input type="checkbox" id="delivered" ${row.confirm_delivered && `checked disabled`}  ${authUser.type ===
        'accountant' && 'disabled'}/> Confirm Delivered </p>
                    <input type="checkbox" id="paid" ${row.confirm_paid && `checked disabled`} ${authUser.type ===
        'delivery' && 'disabled'} /> Confirm Paid <div>`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      preConfirm: () => {
        const delivered = Swal.getPopup().querySelector('#delivered').checked;
        const paid = Swal.getPopup().querySelector('#paid').checked;

        if (delivered || paid) {
          return { delivered: delivered, paid: paid };
        } else {
          Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Confirm is required');
        }
      },
    }).then(result => {
      if (result.isConfirmed) {
        let status = row.status;
        // let deliveredStatus = result.value.delivered;
        // let paidStatus = result.value.paid;

        let deliveredStatus = false;
        let paidStatus = false;

        if (authUser.type === 'superadmin' || authUser.type === 'delivery') {
          deliveredStatus = result.value.delivered;
        }

        if (authUser.type === 'superadmin' || authUser.type === 'accountant') {
          paidStatus = result.value.paid;
        }

        DatabaseService.updateInvoiceConfirmStatus({
          id: row.id,
          status: status,
          deliveredStatus: deliveredStatus,
          paidStatus: paidStatus,
          form: form,
        })
          .then(res => {
            // handleCloseDialog();
            setRow({ ...row, status: res.status, confirm_delivered: res.confirm_delivered, confirm_paid: res.confirm_paid });
            setForm({ ...form, status: res.status });
            let message = '';

            if (res.confirm_delivered && res.confirm_paid) {
              message = 'Status has been updated';
            } else if (res.confirm_delivered) {
              message = `Delivered has been confirmed`;
            } else if (res.confirm_paid) {
              message = `Paid has been confirmed`;
            }

            NotificationManager.success(message, 'Success');
          })
          .catch(err => {
            NotificationManager.error(err, 'Fail');
          });
      }
    });
  };

  const setImage = () => {
    setImgUrls([]);

    if (row?.pictures_path?.length > 0) {
      row.pictures_path.map(item => {
        storage
          .ref(item)
          .getDownloadURL()
          .then(url => {
            setImgUrls(oldArray => [...oldArray, url]);
          })
          .catch(() => {
            console.log('Image not found!');
          });
      });
    }
  };

  const handleChangeCustomStatus = (event, value) => {
    if (value?.name) setForm({ ...form, status: value.name });
  };

  const handleUpdateInvoiceDetail = () => {
    if (row.status !== form.status && authUser.type !== 'superadmin') return;

    MySwal.fire({
      title: 'Please Confirm',
      text: `Comfirm to change bill detail`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        DatabaseService.updateInvoiceDetail({
          id: row.id,
          form: form,
          update_status_by: authUser.email,
          update_status_at: new Date(),
        })
          .then(() => {
            // handleCloseDialog();
            setRow({ ...row, status: form.status });
            NotificationManager.success('Bill detail has been updated', 'Success');
          })
          .catch(err => {
            NotificationManager.error(err, 'Fail');
          });
      }
    });
  };

  const handelSetStatusPrinted = () => {
    setRow({ ...row, status: 'Printed' });
    setForm({ ...form, status: 'Printed' });
  };

  // useEffect(() => {
  //     setImage();
  // }, []);

  useEffect(() => {
    if (row) setImage();
  }, [row]);

  return (
    <div className={typePage !== 'newpage' ? classes.content : ''}>
      <Grid item xs={12}>
        <Box>
          <>
            <div className={classes.root}>
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}>
                  <strong>ลูกค้า:</strong>
                  <span style={{ marginLeft: 43 }}>{row.customer_id}</span>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <strong>วันที่:</strong>
                  <span style={{ marginLeft: 82 }}>
                    {moment(new Date(row.invoice_date.toDate()))
                      .locale('th')
                      .add(543, 'year')
                      .format('LL')}
                  </span>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}>
                  <strong>Sub:</strong>
                  <span style={{ marginLeft: 50 }}>{row.sub ?? ''}</span>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <strong>เลขที่:</strong>
                  <span style={{ marginLeft: 75 }}>{row.invoice_no}</span>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}>
                  <strong>ที่อยู่:</strong>
                  <span style={{ marginLeft: 50 }}>{row.company_address}</span>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <strong>อ้างอิง:</strong>
                  <span style={{ marginLeft: 69 }}>{row.container}</span>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}>
                  <strong>โทรศัพท์:</strong>
                  <span style={{ marginLeft: 23 }}>{row.mobile}</span>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <strong>ผู้สร้างรายการ:</strong>
                  <span style={{ marginLeft: 20 }}>{row.created_by}</span>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}></Grid>

                <Grid item xs={6} sm={6}>
                  <strong>วันที่จัดส่งสินค้า:</strong>
                  <span style={{ marginLeft: 13 }}>
                    {moment(new Date(row.delivery_date.toDate()))
                      .locale('th')
                      .add(543, 'year')
                      .format('LL')}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}></Grid>

                <Grid item xs={6} sm={6}>
                  <strong>ที่อยู่จัดส่ง:</strong>
                  <span style={{ marginLeft: 49 }}>{row.delivery_address}</span>
                </Grid>
              </Grid>
            </div>

            <div style={{ marginTop: 20 }}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <table className={classes.table}>
                    <thead>
                      <tr className={classes.tr}>
                        <th className={classes.th}>Type</th>
                        <th className={classes.th}>Bill No.</th>
                        <th className={classes.th}>Pack</th>
                        <th className={classes.th}>Description</th>
                        <th className={classes.th}>Sub</th>
                        <th className={classes.th}>Warehouse</th>
                        {row.calculation_type === 'Kg' && <th className={classes.th}>Weight</th>}
                        {row.calculation_type === 'M3' && <th className={classes.th}>CBM</th>}
                        <th className={classes.th}>Price</th>
                        <th className={classes.th}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.productNumberList?.map(item => (
                        <tr key={item.po_number}>
                          <td className={classes.td}>{item.type === 'Other' ? '' : item.productType || item.type}</td>
                          <td className={classes.td}>{item.po_number}</td>
                          <td className={classes.td}>{item.packs}</td>
                          <td className={classes.td}>{item.description}</td>
                          <td className={classes.td}>{item.sub_customer}</td>
                          <td className={classes.td}>{item.type === 'Other' ? '' : row.warehouse}</td>
                          {row.calculation_type === 'Kg' && (
                            <td className={classes.td}>
                              {!isNaN(item.weight) && typeof item.weight === 'number' ? item.weight.toFixed(2) : ''}
                            </td>
                          )}
                          {row.calculation_type === 'M3' && (
                            <td className={classes.td}>
                              {!isNaN(item.cbm) && typeof item.cbm === 'number' ? item.cbm.toFixed(4) : ''}
                            </td>
                          )}
                          <td className={classes.td}>{NumberWithCommas(item.price?.toFixed(2))}</td>
                          <td className={classes.td}>{NumberWithCommas(item.total?.toFixed(2))}</td>
                        </tr>
                      ))}

                      <tr>
                        <td className={classes.td}></td>
                        <td className={classes.td}>Grand Total</td>
                        <td className={classes.td}></td>
                        <td className={classes.td}></td>
                        <td className={classes.td}></td>
                        <td className={classes.td}></td>
                        <td className={classes.td}></td>
                        <td className={classes.td}></td>
                        <td className={classes.td}>{NumberWithCommas(row.total.toFixed(2))}</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>

                {imgUrls?.length > 0 &&
                  imgUrls.map((item, idx) => (
                    <Grid item xs={12} sm={3} key={item}>
                      <p>{idx === 0 ? `Pay Slip:` : <br />}</p>
                      <Paper className={classes.paper}>
                        <ModalImage small={item} large={item} alt={row?.invoice_no} />
                      </Paper>
                    </Grid>
                  ))}

                <Grid item xs={12}>
                  <Box>
                    <div>
                      <Grid container spacing={5}>
                        <Grid item xs={5} sm={5}>
                          <TextField
                            multiline
                            rows={4}
                            label="Remark for customer"
                            fullWidth
                            variant="outlined"
                            defaultValue={form.remarkCustomer || ''}
                            onChange={e => {
                              setForm({ ...form, remarkCustomer: e.target.value });
                            }}
                            disabled={typeAction !== 'Edit'}
                          />
                        </Grid>

                        <Grid item xs={5} sm={5}>
                          <TextField
                            multiline
                            rows={4}
                            label="Remark for internal"
                            fullWidth
                            variant="outlined"
                            defaultValue={form.remarkInternal || ''}
                            onChange={e => {
                              setForm({ ...form, remarkInternal: e.target.value });
                            }}
                            disabled={typeAction !== 'Edit'}
                          />
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <Autocomplete
                            closeIcon={false}
                            fullWidth
                            size="small"
                            options={billStatus}
                            getOptionLabel={option => option.name}
                            value={billStatus.find(v => v.name === form.status) || {}}
                            onChange={handleChangeCustomStatus}
                            renderInput={params => <TextField {...params} label="Status" variant="outlined" />}
                            disabled={authUser.type !== 'superadmin' || typeAction !== 'Edit'}
                          />

                          {typeAction === 'Edit' && (
                            <Box mt={2}>
                              <EditButton variant="contained" onClick={handleUpdateInvoiceDetail}>
                                Update
                              </EditButton>
                            </Box>
                          )}
                        </Grid>
                      </Grid>

                      {row.status === 'Cancel' && (
                        <Grid container spacing={5}>
                          <Grid item xs={12} sm={12}>
                            <p>Cancel remark: {row.remark}</p>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </>

          <div className={classes.root} style={{ marginTop: 35 }}>
            <Box component="span" displayPrint="none">
              <Grid container justify="center">
                {typePage === 'newpage' ? (
                  <Button variant="contained" onClick={() => (window.location.href = '/billing-bill')}>
                    Close
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleCloseDialog}>
                    Go Back
                  </Button>
                )}
                {RBAC({ allowedRoles: ['superadmin'] }) && typeAction === 'Edit' && row.status !== 'Cancel' && (
                  <Box ml={2}>
                    <DeleteButton variant="contained" onClick={handleCancel}>
                      Cancel
                    </DeleteButton>
                  </Box>
                )}
                {RBAC({ allowedRoles: ['frontadmin', 'superadmin'] }) && typeAction === 'Edit' && row.status === 'Created' && (
                  <Box ml={2}>
                    <Button color="primary" variant="contained" onClick={() => setOpenDetail(true)}>
                      Print
                    </Button>
                  </Box>
                )}
                {RBAC({ allowedRoles: ['frontadmin', 'superadmin'] }) && typeAction === 'Edit' && row.status === 'Printed' && (
                  <Box ml={2}>
                    <Button color="primary" variant="contained" onClick={handleUploadPaySlip}>
                      Upload Pay Slip
                    </Button>
                  </Box>
                )}
                {RBAC({ allowedRoles: ['frontadmin', 'superadmin'] }) &&
                  typeAction === 'Edit' &&
                  (row.status === 'Uploaded' || row.status === 'Checking' || row.status === 'Checked') && (
                    <Box ml={2}>
                      <Button color="primary" variant="contained" onClick={handleAddPaySlip}>
                        Add Pay Slip
                      </Button>
                    </Box>
                  )}
                {RBAC({ allowedRoles: ['delivery', 'accountant', 'superadmin'] }) &&
                  typeAction === 'Edit' &&
                  (row.status === 'Uploaded' || row.status === 'Checking') && (
                    <Box ml={2}>
                      <Button color="primary" variant="contained" onClick={handleConfirm}>
                        Confirm Delivered or Confirm Paid
                      </Button>
                    </Box>
                  )}
                {RBAC({ allowedRoles: ['superadmin'] }) && typeAction === 'Edit' && row.status === 'Checked' && (
                  <Box ml={2}>
                    <Button color="primary" variant="contained" onClick={() => handleUpdateStatus('Completed')}>
                      Complete
                    </Button>
                  </Box>
                )}
              </Grid>
            </Box>
          </div>
        </Box>
      </Grid>

      {openDetail && (
        <PrintInvoice
          rowSelected={row}
          open={openDetail}
          setOpen={setOpenDetail}
          setOpenDialog={setOpenAction}
          setStatusPrinted={handelSetStatusPrinted}
          form={form}
        />
      )}
    </div>
  );
};

export default Action;
