import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { firestore, storage } from '../../../../../firebase';
import Api from '../../../../../services/api.service';
import DatabaseService from '../../../../../services/database';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditButton, DeleteButton } from '../../../../../components/ColorButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import NumberWithCommas from 'helpers/NumberWithCommas';
import billStatus from 'helpers/BillStatus';
import { Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ModalImage from 'react-modal-image';
import Paper from '@material-ui/core/Paper';
import InvoiceDetail from './detail';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 100,
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#97C1E0 !important',
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

const MySwal = withReactContent(Swal);

const Action = ({ open, setOpenAction, selectedRow, typeAction, showBy }) => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [openDetail, setOpenDetail] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [row, setRow] = useState(selectedRow);
  // const row = selectedRow;
  // const [customStatus, setCustomStatus] = useState(row.status);
  const [imgUrls, setImgUrls] = useState([]);
  const [form, setForm] = useState({
    remarkCustomer: row.remark_customer || '',
    remarkInternal: row.remark_internal || '',
    status: row.status,
  });

  const handleCloseDialog = () => {
    setOpenAction(false);
  };

  return (
    <Dialog fullScreen open={open} onClose={handleCloseDialog} className={classes.dialogRoot}>
      <Box component="span" displayPrint="none">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
              <CloseIcon />
            </IconButton>

            <Typography variant="h2">{`Detail of Invoice Number: ${row.invoice_no}`}</Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <InvoiceDetail open={open} setOpenAction={setOpenAction} selectedRow={row} typeAction={typeAction} />
    </Dialog>
  );
};

export default Action;
