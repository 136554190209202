import { FETCH_SEARCH, FILTER_SEARCH, FETCH_FILTER } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
    initialURL: '/',
    filterList: [],
    searchText: '',
    filterSearch: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_FILTER: {
            return { ...state, filterList: action.payload };
        }
        case FETCH_SEARCH: {
            return { ...state, searchText: action.payload };
        }
        case FILTER_SEARCH: {
            return { ...state, filterSearch: action.payload };
        }
        default:
            return state;
    }
};