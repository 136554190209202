import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyDaHjbcFurfO8zjTxytxYCPVOqxzbZmuFs",
    authDomain: "uat-ttctrackingsystem.firebaseapp.com",
    projectId: "uat-ttctrackingsystem",
    storageBucket: "uat-ttctrackingsystem.appspot.com",
    messagingSenderId: "550594227026",
    appId: "1:550594227026:web:68fbcb266c28def125688b"
};

firebase.initializeApp(config);
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();
export {
    firestore,
    storage,
    auth,
    database,
    googleAuthProvider,
    facebookAuthProvider,
    githubAuthProvider,
    twitterAuthProvider
};
