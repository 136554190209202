import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { firestore, storage } from '../../../../../firebase';
import Api from '../../../../../services/api.service';
import DatabaseService from '../../../../../services/database';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditButton, DeleteButton } from '../../../../../components/ColorButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import NumberWithCommas from 'helpers/NumberWithCommas';
import billStatus from 'helpers/BillStatus';
import { Card, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ModalImage from 'react-modal-image';
import Paper from '@material-ui/core/Paper';
import InvoiceDetail from './detail';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#97C1E0 !important',
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

const MySwal = withReactContent(Swal);

const BillNewPage = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [openDetail, setOpenDetail] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [row, setRow] = useState();
  const [customStatus, setCustomStatus] = useState();
  // const [openAction, setOpenAction] = useState(true);
  const [typeAction, setTypeAction] = useState('Detail');
  const [openAction, setOpenAction] = useState(false);

  const fetchData = () => {
    DatabaseService.getBillByNo(id).then(res => {
      setRow(res);
      setCustomStatus(res.status);
      if (checkPermission(res.status)) setTypeAction('Edit');
    });
  };

  const checkPermission = status => {
    switch (authUser.type) {
      case 'frontadmin':
        if (status === 'Created' || status === 'Printed') {
          return true;
        }
        break;
      case 'accountant':
        if (status === 'Uploaded' || status === 'Checking') {
          return true;
        }
        break;
      case 'delivery':
        if (status === 'Uploaded' || status === 'Checking') {
          return true;
        }
        break;
      case 'admin':
        if (status === 'Created') {
          return true;
        }
        break;
      case 'superadmin':
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {row && (
        <Card>
          <CardContent>
            <InvoiceDetail
              open={openAction}
              setOpenAction={setOpenAction}
              selectedRow={row}
              typeAction={'Edit'}
              typePage={'newpage'}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default BillNewPage;
