import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import { firestore } from '../../../../../firebase';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DatabaseService from '../../../../../services/database';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Api from 'services/api.service';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  appBar: {
    position: 'relative',
  },
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
  },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(5),
    marginTop: 20,
  },
  paper: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  content: {
    padding: '35px 35px 0px 35px',
  },
  label: {
    fontWeight: 'bold',
    margin: 20,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#97C1E0 !important',
  },
  [`@media print`]: {
    tr: {
      backgroundColor: '#97C1E0 !important',
    },
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

const MySwal = withReactContent(Swal);

const defaultValues = {
  customer_id: '',
  company_address: '',
  mobile: '',
  delivery_note_no: 'xxxxxxxxxx',
  delivery_note_date: new Date(),
};

const validationSchema = yup.object().shape({
  customer_id: yup.string().required('Customer ID is required'),
  container: yup.string().required('Container is required'),
  driver: yup.string().required('Driver is required'),
  delivery_note_date: yup.date(),

  productNumberList: yup.array().of(
    yup.object().shape({
      po_number: yup.string().required('Item is required'),
      description: yup.string().required('Description is required'),
    }),
  ),
});

const CreateDeliveryNote = props => {
  const { open, setOpen } = props;
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productNumberList',
  });

  const { authUser } = useSelector(({ auth }) => auth);
  // const { customers } = useSelector(({ master }) => master);
  const { drivers } = useSelector(({ master }) => master);
  const [customers, setCustomers] = useState([]);
  const [subList, setSubList] = useState([]);
  const [pending, setPending] = useState(false);
  const [customerInfo, setCustomerInfo] = useState();
  const [customerId, setCustomerId] = useState();
  const [subId, setSubId] = useState();
  const [containerId, setContainerId] = useState();
  const [containerList, setContainerList] = useState([]);
  const [checkedIssued, setCheckedIssued] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchContainer = () => {
    const docRef = firestore.collection('orders');
    let query = docRef.where('status', '!=', 'WH China');

    query.onSnapshot(snapShot => {
      let tempDataArray = [];
      snapShot.forEach(doc => {
        if (doc.exists) {
          let data = doc.data();
          data.id = doc.id;

          let obj = tempDataArray.find(o => o.container === data.container);
          if (!obj) {
            tempDataArray = [...tempDataArray, data];
          }
        }
      });
      setContainerList(() => tempDataArray);
    });
  };

  const fetchCustomers = () => {
    const docRef = firestore.collection('orders');
    const query = docRef.where('container', '==', containerId);

    query.onSnapshot(snapShot => {
      let tempDataArray = [];
      snapShot.forEach(doc => {
        if (doc.exists) {
          let data = doc.data();
          data.id = doc.id;

          let obj = tempDataArray.find(o => o.productNumber === data.po_number);
          if (!obj) {
            tempDataArray = [...tempDataArray, data];
          }
        }
      });

      const customers = tempDataArray.map(item => item['customer_id']);
      const uniqueCustomer = customers.filter((elem, index, self) => index === self.indexOf(elem));

      setCustomers(uniqueCustomer);
    });
  };

  const fetchCustomerInfo = () => {
    if (customerId) {
      const ref = firestore.collection('customers');
      const query = ref.where('name', '==', customerId);

      query.get().then(async querySnapshot => {
        if (querySnapshot.size > 0) {
          const data = querySnapshot.docs[0].data();
          setCustomerInfo(data);
          setValue('company_address', data?.company_address ?? '');
          setValue('mobile', data?.mobile ?? '');
        }
      });
    } else {
      setValue('company_address', '');
      setValue('mobile', '');
      setValue('sub_id', '');
    }
  };

  const fetchSubByCustomerId = () => {
    if (!customerId) return;

    const ref = firestore.collection('sub');
    const query = ref.where('customer_id', '==', customerId);

    query.get().then(async querySnapshot => {
      let tempDataArray = [];

      querySnapshot.forEach(doc => {
        if (doc.exists) {
          let data = doc.data();
          data.id = doc.id;

          tempDataArray = [...tempDataArray, data];
        }
      });

      setSubList(tempDataArray);
    });
  };

  const fetchPoNumberList = () => {
    remove();
    setPending(true);

    if (!customerId) return;

    const docRef = firestore.collection('orders');
    const query = docRef
      // .where('status', 'not-in', ['WH China', 'Closed Container'])
      .where('container', '==', containerId)
      .where('customer_id', '==', customerId.trim());

    query.onSnapshot(snapShot => {
      let tempDataArray = [];
      snapShot.forEach(doc => {
        if (doc.exists) {
          let data = doc.data();
          data.id = doc.id;

          let obj = tempDataArray.find(o => o.productNumber === data.po_number);
          if (!obj) {
            tempDataArray = [...tempDataArray, data];
          }
        }
      });

      setPending(false);

      if (subId && subId !== 'changeCustomer') tempDataArray = tempDataArray.filter(x => x.sub_customer === subId.trim());

      if (checkedIssued) {
        append(tempDataArray);
      } else {
        append(tempDataArray.filter(a => !a.created_delivery_note_date || typeof a.created_delivery_note_date === 'string'));
      }
    });
  };

  const handleDialog = () => {
    setOpen(false);
  };

  const handleChecedIssued = e => {
    setCheckedIssued(e.target.checked);
  };

  const onSubmit = data => {
    if (!checkedIssued) {
      const filterIssued = data.productNumberList.filter(
        x => !x.created_delivery_note_date || typeof x.created_delivery_note_date === 'string',
      );
      data.productNumberList = filterIssued;
    }

    if (data.productNumberList.length > 0) {
      const customerId = data.sub_id ? `${data.customer_id} -> ${data.sub_id}` : `${data.customer_id}`;
      data.customer_id = [customerId];

      setPending(true);
      Api.addDelivery(data)
        .then(res => {
          setPending(false);
          handleDialog();
          MySwal.fire({
            title: 'Success',
            text: `Delivery note has been created. Delivery Note Number is ${res.deliveryNoteNo}`,
          });
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    } else {
      MySwal.fire({
        title: 'Error',
        text: `Item is Required`,
      });
    }
  };

  useEffect(() => {
    fetchContainer();
  }, []);

  useEffect(() => {
    if (containerId) fetchCustomers();
  }, [containerId]);

  useEffect(() => {
    fetchCustomerInfo();
    fetchPoNumberList();
    fetchSubByCustomerId();
  }, [customerId]);

  useEffect(() => {
    if (customerId) fetchPoNumberList();
  }, [checkedIssued]);

  useEffect(() => {
    if (subId !== 'changeCustomer') fetchPoNumberList();
  }, [subId]);

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialogRoot} maxWidth={'lg'} fullWidth>
      <DialogTitle className={classes.dialogTitleRoot}>Create New Delivery Note</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="delivery_note_date"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <KeyboardDatePicker
                      {...rest}
                      size="small"
                      label="Delivery Note Date"
                      format="dd/MM/yyyy"
                      variant="inline"
                      inputVariant="outlined"
                      fullWidth
                      error={errors.delivery_note_date && true}
                      helperText={errors.delivery_note_date?.message}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="container"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      setContainerId(item?.container);
                      setValue('customer_id', '');
                      setCustomerId();
                      onChange(item?.container);
                    }}
                    value={containerList.find(v => v.container === value) || {}}
                    options={containerList}
                    getOptionLabel={option => option.container?.toString() || ''}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Container"
                        variant="outlined"
                        error={errors.container && true}
                        helperText={errors.container?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                name="customer_id"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      setCustomerId(item);
                      setSubId('changeCustomer');
                      setValue('sub_id', '');
                      onChange(item);
                    }}
                    value={customers.find(v => v === value) || ''}
                    options={customers.sort((a, b) => (a > b ? 1 : -1))}
                    getOptionLabel={option => option?.toString() || ''}
                    disabled={!containerId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Customer ID"
                        variant="outlined"
                        error={errors.customer_id && true}
                        helperText={errors.customer_id?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                name="sub_id"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      setSubId(item?.name);
                      setValue('company_address', item ? item?.company_address ?? '' : customerInfo.company_address);
                      setValue('mobile', item ? item?.mobile ?? '' : customerInfo.mobile);
                      onChange(item?.name);
                    }}
                    value={subList.find(v => v.name === value) || ''}
                    options={subList.sort((a, b) => (a.name > b.name ? 1 : -1))}
                    getOptionLabel={option => option.name?.toString() || ''}
                    disabled={!customerId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Sub ID"
                        variant="outlined"
                        error={errors.sub_id && true}
                        helperText={errors.sub_id?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField {...field} size="small" label="Phone Number" variant="outlined" disabled fullWidth />
                )}
                name="mobile"
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    size="small"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                )}
                name="company_address"
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="driver"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      onChange(item?.name);
                    }}
                    value={drivers.find(v => v.name === value) || ''}
                    options={drivers}
                    getOptionLabel={option => option.name?.toString() || ''}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Driver"
                        variant="outlined"
                        error={errors.driver && true}
                        helperText={errors.driver?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox name="checked" />}
                name="checked"
                onChange={e => handleChecedIssued(e)}
                label={<p style={{ color: '#F6C276' }}>***Select delivery note issued</p>}
              />
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: '#E6E6E6' }}>
                    <TableRow>
                      <TableCell width={300}>Date</TableCell>
                      <TableCell width={200}>Bill No.</TableCell>
                      <TableCell width={300}>Description</TableCell>
                      <TableCell width={100}>Pack</TableCell>
                      <TableCell width={100}>Weight</TableCell>
                      <TableCell width={100}>Length</TableCell>
                      <TableCell width={100}>Width</TableCell>
                      <TableCell width={100}>Height</TableCell>
                      <TableCell width={150}>CBM</TableCell>
                      <TableCell width={150}>
                        {' '}
                        <Button variant="contained" color="secondary" onClick={fetchPoNumberList} disabled={!containerId}>
                          Reload
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.length === 0 ? (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={10}>
                          <div style={{ textAlign: 'center' }}>
                            <span>
                              <div>No Data Available in Table</div>
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      fields.map((field, index) => (
                        <TableRow key={field.id}>
                          <TableCell component="th" scope="row">
                            <p>
                              {moment(new Date((field.date.seconds + field.date.nanoseconds * 10 ** -9) * 1000)).format(
                                'DD/MM/YYYY',
                              ) || ''}
                            </p>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {field.created_delivery_note_date && typeof field.created_delivery_note_date !== 'string' && (
                              <p style={{ color: field.created_delivery_note_date && '#F6C276' }}>
                                {' '}
                                {'***Delivery Note issued'} <br />
                              </p>
                            )}
                            <p>{field.po_number || ''}</p>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.description || ''}</p>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.packs || ''}</p>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.weight || ''}</p>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.length || ''}</p>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.width || ''}</p>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{field.height || ''}</p>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <p>{parseFloat(field.cbm).toFixed(4) || ''}</p>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Button variant="contained" onClick={() => remove(index)}>
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mb={4} mt={4}>
            <Button onClick={handleDialog} disabled={pending}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button type="submit" variant="contained" color="primary" disabled={pending}>
                Create
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CreateDeliveryNote;
