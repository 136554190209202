import { GET_ALL_CUSTOMERS, GET_ALL_DRIVER, GET_ALL_SUB } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  initialURL: '/',
  customers: [],
  sub: [],
  drivers: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_CUSTOMERS: {
      return { ...state, customers: action.payload };
    }
    case GET_ALL_SUB: {
      return { ...state, sub: action.payload };
    }
    case GET_ALL_DRIVER: {
      return { ...state, drivers: action.payload };
    }
    default:
      return state;
  }
};
