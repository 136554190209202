import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import { firestore } from '../../../../../firebase';
import Dialog from '@material-ui/core/Dialog';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NumberFormatCustom from '../../../../../helpers/NumberFormatCustom';
import DatabaseService from '../../../../../services/database';
import { NotificationManager } from 'react-notifications';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  appBar: {
    position: 'relative',
  },
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
  },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(5),
    marginTop: 20,
  },
  paper: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  content: {
    padding: '35px 35px 0px 35px',
  },
  label: {
    fontWeight: 'bold',
    margin: 20,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#97C1E0 !important',
  },
  [`@media print`]: {
    tr: {
      backgroundColor: '#97C1E0 !important',
    },
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

const defaultValues = {
  customer_id: '',
  company_address: '',
  mobile: '',
  invoice_no: 'xxxxxxxxxx',
  invoice_date: new Date(),
  delivery_date: new Date(),
  delivery_address: '',
};

const validationSchema = yup.object().shape({
  customer_id: yup.string().required('Customer ID is required'),
  container: yup.string().required('Container is required'),
  calculation_type: yup.string().required('Calculation Type is required'),
  warehouse: yup.string().required('Warehouse is required'),
  delivery_address: yup.string().required('Delivery address is required'),
  invoice_date: yup.date(),
  delivery_date: yup.date(),

  productNumberList: yup.array().of(
    yup.object().shape({
      price: yup
        .number()
        .typeError('*Price is required')
        .required('*Price is required'),
      // .min(1, "Price must be greater than 0"),
      po_number: yup.string().required('Item is required'),
    }),
  ),
});

const MySwal = withReactContent(Swal);

const CreateBill = props => {
  const { open, setOpen } = props;
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productNumberList',
  });
  const watch = useWatch({ control, name: 'productNumberList' });

  const { authUser } = useSelector(({ auth }) => auth);
  const { customers } = useSelector(({ master }) => master);
  const [pending, setPending] = useState(false);
  const [customerInfo, setCustomerInfo] = useState();
  const [customerId, setCustomerId] = useState();
  const [containerId, setContainerId] = useState();
  const [containerList, setContainerList] = useState([]);
  const [calculationType, setCalculationType] = useState();
  const [selectedWarehouse, setSelectedWarehouse] = useState();
  const [checkedAddress, setCheckedAddress] = useState('other');
  const [checkedIssued, setCheckedIssued] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchCustomerInfo = () => {
    const ref = firestore.collection('customers');
    const query = ref.where('name', '==', customerId);

    query.get().then(async querySnapshot => {
      if (querySnapshot.size > 0) {
        const data = querySnapshot.docs[0].data();
        setCustomerInfo(data);
        if (checkedAddress === 'current') setValue('delivery_address', data.company_address);
      }
    });
  };

  const fetchContainer = () => {
    const docRef = firestore.collection('orders');
    let query = docRef.where('customer_id', '==', customerId).where('status', '!=', 'WH China');

    query.onSnapshot(snapShot => {
      // let tempDataArray = [];

      let tempDataArray = [];
      setContainerList([]);

      snapShot.forEach(doc => {
        if (doc.exists) {
          let data = doc.data();
          data.id = doc.id;

          // let obj = tempDataArray.find(o => o.container === data.container);
          // if (!obj) {
          //     tempDataArray = [
          //         ...tempDataArray,
          //         data,
          //     ];
          // }

          let obj = tempDataArray.find(o => o.container === data.container);

          if (!obj) {
            if (!data.invoice_no) {
              setContainerList(oldArray => [...oldArray, data]);
              tempDataArray = [...tempDataArray, data];

              // tempDataArray = [
              //     ...tempDataArray,
              //     data,
              // ];
            } else {
              const refInvoice = firestore.collection('invoices');
              const query = refInvoice.where('invoice_no', '==', data.invoice_no);

              // console.log(data.invoice_no)

              query.get().then(async querySnapshot => {
                if (querySnapshot.size > 0) {
                  const invoiceStatus = querySnapshot.docs[0].data().status;
                  if (invoiceStatus === 'Created' || invoiceStatus === 'Printed' || invoiceStatus === 'Cancel') {
                    // tempDataArray = [
                    //     ...tempDataArray,
                    //     data,
                    // ];
                    setContainerList(oldArray => [...oldArray, data]);
                    tempDataArray = [...tempDataArray, data];
                  }
                }
              });
            }
          }
        }
      });

      // setContainerList(() => tempDataArray);
    });
  };

  const handleSetTotal = (index, price, unit, type) => {
    if (!price) price = 0;

    if (type === 'Other') {
      setValue(`productNumberList[${index}].total`, price);
    } else {
      if (!unit) unit = 0;

      const total = parseFloat(price) * parseFloat(unit);
      setValue(`productNumberList[${index}].total`, total.toFixed(2));
    }
  };

  const fetchPoNumberList = () => {
    remove();
    setPending(true);

    const docRef = firestore.collection('orders');
    const query = docRef
      // .where('status', '!=', 'WH China')
      .where('container', '==', containerId)
      .where('customer_id', '==', customerId);

    query.onSnapshot(snapShot => {
      let tempDataArray = [];
      snapShot.forEach(doc => {
        if (doc.exists) {
          let data = doc.data();
          data.id = doc.id;
          data.checked = true;

          if (customerInfo.customer_price) {
            const customerPrice = customerInfo.customer_price.find(
              item => item.type === (data.type || data.productType) && item.warehouse === selectedWarehouse,
            );
            if (customerPrice) {
              if (calculationType === 'Kg' && data.transportationType === 'EK') {
                const price = customerPrice['kg_ek_price'];
                data.price = price;
                data.total = price * data.weight;
              } else if (calculationType === 'Kg' && data.transportationType === 'SEA') {
                const price = customerPrice['kg_sea_price'];
                data.price = price;
                data.total = price * data.weight;
              } else if (calculationType === 'M3' && data.transportationType === 'EK') {
                const price = customerPrice['m3_ek_price'];
                data.price = price;
                data.total = price * data.cbm?.toFixed(4);
              } else if (calculationType === 'M3' && data.transportationType === 'SEA') {
                const price = customerPrice['m3_sea_price'];
                data.price = price;
                data.total = price * data.cbm?.toFixed(4);
              } else {
                data.price = 0;
                data.total = 0;
              }
            }
          }

          let obj = tempDataArray.find(o => o.productNumber === data.po_number);
          if (!obj) {
            tempDataArray = [...tempDataArray, data];
          }
        }
      });

      setPending(false);

      if (checkedIssued) {
        append(tempDataArray);
      } else {
        append(tempDataArray.filter(a => !a.created_bill_date));
      }
    });
  };

  const handleChangeAddress = e => {
    setCheckedAddress(e.target.value);

    if (e.target.value === 'current') setValue('delivery_address', customerInfo?.company_address);
    else setValue('delivery_address', '');
  };

  const handleDialog = () => {
    setOpen(false);
  };

  const handleChecedIssued = e => {
    setCheckedIssued(e.target.checked);
  };

  const onSubmit = data => {
    if (!checkedIssued) {
      const filterIssued = data.productNumberList.filter(x => !x.created_bill_date);
      data.productNumberList = filterIssued;
    }

    if (data.productNumberList.length > 0) {
      setPending(true);
      DatabaseService.addInvoice({ data: data, authUser: authUser })
        .then(res => {
          setPending(false);
          handleDialog();
          MySwal.fire({
            title: 'Success',
            text: `Invoice has been created. Invoice Number is ${res.invoiceNo}`,
          });
        })
        .catch(err => {
          NotificationManager.error(err, 'Error');
        });
    } else {
      MySwal.fire({
        title: 'Error',
        text: `Item is Required`,
      });
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchContainer();
      fetchCustomerInfo();
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId && containerId && calculationType && selectedWarehouse) fetchPoNumberList();
  }, [customerId, containerId, calculationType, selectedWarehouse]);

  useEffect(() => {
    if (customerId && containerId && calculationType && selectedWarehouse) fetchPoNumberList();
  }, [checkedIssued]);

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialogRoot} maxWidth={'lg'} fullWidth>
      {/* <Dialog open={open} onClose={handleClose} className={classes.dialogRoot} maxWidth={'md'} fullWidth></Dialog> */}
      <DialogTitle className={classes.dialogTitleRoot}>Create New Bill</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="invoice_date"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <KeyboardDatePicker
                      {...rest}
                      size="small"
                      label="Invoice Date"
                      format="dd/MM/yyyy"
                      variant="inline"
                      inputVariant="outlined"
                      fullWidth
                      error={errors.invoice_date && true}
                      helperText={errors.invoice_date?.message}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {/* </Grid>
             */}

            <Grid item xs={6}>
              <Controller
                control={control}
                name="customer_id"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      setValue('company_address', item?.company_address ?? '');
                      setValue('mobile', item?.mobile ?? '');
                      setCustomerId(item?.name);
                      onChange(item?.name);
                    }}
                    value={customers.find(v => v.name === value) || {}}
                    options={customers}
                    getOptionLabel={option => option.name?.toString() || ''}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Customer ID"
                        variant="outlined"
                        error={errors.customer_id && true}
                        helperText={errors.customer_id?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField {...field} size="small" label="Phone Number" variant="outlined" disabled fullWidth />
                )}
                name="mobile"
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    size="small"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                )}
                name="company_address"
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="container"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      setContainerId(item?.container);
                      onChange(item?.container);
                    }}
                    value={containerList.find(v => v.container === value) || {}}
                    options={containerList}
                    getOptionLabel={option => option.container?.toString() || ''}
                    disabled={!customerId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Container"
                        variant="outlined"
                        error={errors.container && true}
                        helperText={errors.container?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                name="calculation_type"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      setCalculationType(item?.name);
                      onChange(item?.name);
                    }}
                    value={CALCULATION_TYPE.find(v => v.name === value) || {}}
                    options={CALCULATION_TYPE}
                    getOptionLabel={option => option.name?.toString() || ''}
                    disabled={!containerId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Calculation Type"
                        variant="outlined"
                        error={errors.calculation_type && true}
                        helperText={errors.calculation_type?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                control={control}
                name="warehouse"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="small"
                    onChange={(event, item) => {
                      setSelectedWarehouse(item?.name);
                      onChange(item?.name);
                    }}
                    value={WAREHOUSE.find(v => v.name === value) || {}}
                    options={WAREHOUSE}
                    getOptionLabel={option => option.name?.toString() || ''}
                    disabled={!containerId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Warehouse"
                        variant="outlined"
                        error={errors.warehouse && true}
                        helperText={errors.warehouse?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox name="checked" />}
                name="checked"
                onChange={e => handleChecedIssued(e)}
                label={<p style={{ color: '#F6C276' }}>***Select invoice issued</p>}
              />
            </Grid>

            {/* {fields.length > 0 && */}
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ backgroundColor: '#E6E6E6' }}>
                    <TableRow>
                      <TableCell width={150}>Type</TableCell>
                      <TableCell width={150}>Transportation</TableCell>
                      <TableCell width={200}>Bill No.</TableCell>
                      <TableCell width={150}>Pack</TableCell>
                      <TableCell width={150}>Description</TableCell>
                      <TableCell width={150}>Sub</TableCell>
                      <TableCell width={150}>Warehouse</TableCell>
                      {calculationType === 'Kg' && <TableCell width={150}>Weight</TableCell>}
                      {calculationType === 'M3' && <TableCell width={150}>CBM</TableCell>}
                      <TableCell width={150}>Price</TableCell>
                      <TableCell width={150}>Total</TableCell>
                      <TableCell width={150}>
                        {' '}
                        <Button variant="contained" color="secondary" onClick={() => append({ type: 'Other' })}>
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.length === 0 ? (
                      <TableRow>
                        <TableCell component="th" scope="row" colSpan={11}>
                          <div style={{ textAlign: 'center' }}>
                            <span>
                              <div>No Data Available in Table</div>
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      fields.map((field, index) => (
                        <TableRow key={field.id}>
                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => <TextField {...field} disabled fullWidth />}
                              name={`productNumberList[${index}].type`}
                              control={control}
                              defaultValue={field.type || field.productType || ''}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => <TextField {...field} disabled fullWidth />}
                              name={`productNumberList[${index}].transportationType`}
                              control={control}
                              defaultValue={field.transportationType || ''}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {field.created_bill_date && <p style={{ color: '#F6C276' }}>***Invoice issued</p>}

                            <Controller
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  disabled={watch && watch[index]?.type !== 'Other'}
                                  fullWidth
                                  error={errors.productNumberList?.[index]?.po_number && true}
                                  helperText={errors.productNumberList?.[index]?.po_number?.message}
                                />
                              )}
                              name={`productNumberList[${index}].po_number`}
                              control={control}
                              defaultValue={field.po_number || ''}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => <TextField {...field} disabled fullWidth />}
                              name={`productNumberList[${index}].packs`}
                              control={control}
                              defaultValue={field.packs || ''}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => <TextField {...field} disabled fullWidth />}
                              name={`productNumberList[${index}].description`}
                              control={control}
                              defaultValue={field.description || ''}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => <TextField {...field} disabled fullWidth />}
                              name={`productNumberList[${index}].sub_customer`}
                              control={control}
                              defaultValue={field.sub_customer || ''}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField disabled fullWidth value={watch[index]?.type == 'Other' ? '' : selectedWarehouse} />
                          </TableCell>
                          {calculationType === 'Kg' && (
                            <TableCell component="th" scope="row">
                              <Controller
                                render={({ field }) => <TextField {...field} disabled fullWidth />}
                                name={`productNumberList[${index}].weight`}
                                control={control}
                                defaultValue={field.weight || ''}
                              />
                            </TableCell>
                          )}
                          {calculationType === 'M3' && (
                            <TableCell component="th" scope="row">
                              <Controller
                                render={({ field }) => <TextField {...field} disabled fullWidth />}
                                name={`productNumberList[${index}].cbm`}
                                control={control}
                                defaultValue={typeof field.cbm === 'number' ? field.cbm.toFixed(4) : ''}
                              />
                            </TableCell>
                          )}
                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  // disabled
                                  onChange={e => {
                                    const price = e.target.value;
                                    handleSetTotal(
                                      index,
                                      price,
                                      calculationType === 'Kg' ? watch[index].weight : watch[index].cbm,
                                      watch[index]?.type,
                                    );
                                    field.onChange(price);
                                  }}
                                  fullWidth
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  error={errors.productNumberList?.[index]?.price && true}
                                  helperText={errors.productNumberList?.[index]?.price?.message}
                                />
                              )}
                              name={`productNumberList[${index}].price`}
                              control={control}
                              defaultValue={field.price || 0}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Controller
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  disabled
                                  fullWidth
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                />
                              )}
                              name={`productNumberList[${index}].total`}
                              control={control}
                              defaultValue={field.total || 0}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Button variant="contained" onClick={() => remove(index)}>
                              X
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {/* } */}

            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name="delivery_date"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <KeyboardDatePicker
                      {...rest}
                      size="small"
                      label="Delivery Date"
                      format="dd/MM/yyyy"
                      variant="inline"
                      inputVariant="outlined"
                      fullWidth
                      error={errors.delivery_date && true}
                      helperText={errors.delivery_date?.message}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="other"
                  value={checkedAddress}
                  onChange={e => {
                    handleChangeAddress(e);
                  }}>
                  <FormControlLabel value="other" control={<Radio color="primary" />} label="Other Address" />
                  <FormControlLabel value="current" control={<Radio color="primary" />} label="Current Address" />
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    size="small"
                    label="Delivery Address"
                    variant="outlined"
                    fullWidth
                    error={errors.delivery_address && true}
                    helperText={errors.delivery_address?.message}
                  />
                )}
                name="delivery_address"
                control={control}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mb={4} mt={4}>
            <Button onClick={handleDialog} disabled={pending}>
              Cancel
            </Button>
            <Box ml={2}>
              <Button type="submit" variant="contained" color="primary" disabled={pending}>
                Create
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CreateBill;

const CALCULATION_TYPE = [{ name: 'Kg' }, { name: 'M3' }];
const WAREHOUSE = [{ name: 'กวางโจว' }, { name: 'อิอู' }];
