const billStatus = [
  { name: 'Created' },
  { name: 'Printed' },
  { name: 'Uploaded' },
  { name: 'Checking' },
  { name: 'Checked' },
  { name: 'Completed' },
  { name: 'Cancel' },
];

export default billStatus;
