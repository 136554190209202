import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { firestore, storage } from '../../../../firebase';
import Api from '../../../../services/api.service';
import DatabaseService from '../../../../services/database';
import { NotificationManager } from 'react-notifications';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EditButton, DeleteButton } from '../../../../components/ColorButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import NumberWithCommas from '../../../../helpers/NumberWithCommas';
import { Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ModalImage from 'react-modal-image';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 100,
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#97C1E0 !important',
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

const MySwal = withReactContent(Swal);

const Action = ({ open, setOpenAction, selectedRow, typeAction, showBy }) => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const [openDetail, setOpenDetail] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const row = selectedRow;

  const handleCloseDialog = () => {
    setOpenAction(false);
  };

  const handleUpdateStatus = status => {
    MySwal.fire({
      title: 'Please Confirm',
      text: `Comfirm to change status to ${status}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        DatabaseService.updateInvoiceStatus({
          id: row.id,
          status: status,
          update_status_by: authUser.email,
          update_status_at: new Date(),
        })
          .then(() => {
            handleCloseDialog();
            NotificationManager.success('Status has been updated', 'Success');
          })
          .catch(err => {
            NotificationManager.error(err, 'Fail');
          });
      }
    });
  };

  const handleUploadPaySlip = async () => {
    // handleCloseDialog();

    Swal.fire({
      title: 'Select Pay Slip',
      text: 'Upload Pay Slip to change status to Uploaded',
      input: 'file',
      inputAttributes: {
        accept: 'image/*',
        'aria-label': 'Upload your profile picture',
      },
      showCancelButton: true,
      confirmButtonText: 'Upload',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      preConfirm: file => {
        if (!file) {
          Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Pay Slip is required');
        } else {
          DatabaseService.uploadSlip({ file: file, invoiceNo: row.invoice_no }).then(() => {
            const reader = new FileReader();
            reader.onload = e => {
              Swal.fire({
                html: `Invoice Number <br/> ${row.invoice_no} uploaded Pay Slip`,
                imageUrl: e.target.result,
                imageAlt: 'The uploaded Pay Slip',
              });
            };
            reader.readAsDataURL(file);

            DatabaseService.updateInvoiceStatus({
              id: row.id,
              status: 'Uploaded',
              update_status_by: authUser.email,
              update_status_at: new Date(),
            })
              .then(() => {
                handleCloseDialog();
                NotificationManager.success('Status has been updated', 'Success');
              })
              .catch(err => {
                NotificationManager.error(err, 'Fail');
              });
          });
        }
      },
    });
  };

  const handleConfirm = () => {
    // handleCloseDialog();

    MySwal.fire({
      title: 'Please Confirm',
      html: `<div><input type="checkbox" id="delivered" ${row.confirm_delivered && `checked disabled`}  ${authUser.type ===
        'accountant' && 'disabled'}/> Confirm Delivered </p>
                    <input type="checkbox" id="paid" ${row.confirm_paid && `checked disabled`} ${authUser.type ===
        'delivery' && 'disabled'} /> Confirm Paid <div>`,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      preConfirm: () => {
        const delivered = Swal.getPopup().querySelector('#delivered').checked;
        const paid = Swal.getPopup().querySelector('#paid').checked;

        if (delivered || paid) {
          return { delivered: delivered, paid: paid };
        } else {
          Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Confirm is required');
        }
      },
    }).then(result => {
      if (result.isConfirmed) {
        let status = row.status;
        let confirm_delivered = false;
        let confirm_paid = false;

        if (authUser.type === 'superadmin' || authUser.type === 'delivery') {
          confirm_delivered = result.value.delivered;
        }

        if (authUser.type === 'superadmin' || authUser.type === 'accountant') {
          confirm_paid = result.value.paid;
        }

        if (confirm_delivered && confirm_paid) {
          status = 'Checked';
        } else if (confirm_delivered || confirm_paid) {
          status = 'Checking';
        }

        DatabaseService.updateInvoiceConfirmStatus({
          id: row.id,
          status: status,
          confirm_delivered: confirm_delivered,
          confirm_paid: confirm_paid,
        })
          .then(() => {
            handleCloseDialog();
            let message = '';

            if (confirm_delivered && confirm_paid) {
              message = 'Status has been updated';
            } else if (confirm_delivered) {
              message = `Delivered has been confirmed`;
            } else if (confirm_paid) {
              message = `Paid has been confirmed`;
            }

            NotificationManager.success(message, 'Success');
          })
          .catch(err => {
            NotificationManager.error(err, 'Fail');
          });
      }
    });
  };

  const setImage = () => {
    if (row.invoice_no) {
      storage
        .ref('slip')
        .child(`${row.invoice_no}`)
        .getDownloadURL()
        .then(url => {
          setImgUrl(url);
        })
        .catch(() => {
          console.log('Image not found!');
        });
    }
  };

  useEffect(() => {
    setImage();
  }, []);

  return (
    <Dialog fullScreen open={open} onClose={handleCloseDialog} className={classes.dialogRoot}>
      <Box component="span" displayPrint="none">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
              <CloseIcon />
            </IconButton>

            <Typography variant="h2">{`Detail of Invoice Number: ${row.invoice_no}`}</Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <div className={classes.content}>
        <Grid item xs={12}>
          <Box>
            <>
              <div className={classes.root}>
                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}>
                    <strong>ลูกค้า:</strong>
                    <span style={{ marginLeft: 43 }}>{row.customer_id}</span>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>วันที่:</strong>
                    <span style={{ marginLeft: 82 }}>
                      {moment(new Date(row.invoice_date.toDate()))
                        .locale('th')
                        .add(543, 'year')
                        .format('LL')}
                    </span>
                  </Grid>
                </Grid>

                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}>
                    <strong>ที่อยู่:</strong>
                    <span style={{ marginLeft: 50 }}>{row.company_address}</span>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>เลขที่:</strong>
                    <span style={{ marginLeft: 75 }}>{row.invoice_no}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}>
                    <strong>โทรศัพท์:</strong>
                    <span style={{ marginLeft: 23 }}>{row.mobile}</span>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>อ้างอิง:</strong>
                    <span style={{ marginLeft: 69 }}>{row.container}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}></Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>ผู้สร้างรายการ:</strong>
                    <span style={{ marginLeft: 20 }}>{row.created_by}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}></Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>วันที่จัดส่งสินค้า:</strong>
                    <span style={{ marginLeft: 13 }}>
                      {moment(new Date(row.delivery_date.toDate()))
                        .locale('th')
                        .add(543, 'year')
                        .format('LL')}
                    </span>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={6} sm={6}></Grid>

                  <Grid item xs={6} sm={6}>
                    <strong>ที่อยู่จัดส่ง:</strong>
                    <span style={{ marginLeft: 49 }}>{row.delivery_address}</span>
                  </Grid>
                </Grid>
              </div>

              <div style={{ marginTop: 20 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <table className={classes.table}>
                      <thead>
                        <tr className={classes.tr}>
                          <th className={classes.th}>Type</th>
                          <th className={classes.th}>Bill No.</th>
                          {row.calculation_type === 'Kg' && <th className={classes.th}>Weight</th>}
                          {row.calculation_type === 'M3' && <th className={classes.th}>CBM</th>}
                          <th className={classes.th}>Price</th>
                          <th className={classes.th}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.productNumberList?.map(item => (
                          <tr key={item.po_number}>
                            <td className={classes.td}>{item.type === 'Other' ? '' : item.productType || item.type}</td>
                            <td className={classes.td}>{item.po_number}</td>
                            {row.calculation_type === 'Kg' && <td className={classes.td}>{item.weight ?? 0}</td>}
                            {row.calculation_type === 'M3' && (
                              <td className={classes.td}>
                                {!isNaN(item.cbm) && typeof item.cbm === 'number' ? item.cbm.toFixed(4) : ''}
                              </td>
                            )}
                            <td className={classes.td}>{NumberWithCommas(item.price?.toFixed(2))}</td>
                            <td className={classes.td}>{NumberWithCommas(item.total?.toFixed(2))}</td>
                          </tr>
                        ))}

                        <tr>
                          <td className={classes.td}></td>
                          <td className={classes.td}></td>
                          <td className={classes.td}>Grand Total</td>
                          <td className={classes.td}></td>
                          <td className={classes.td}>{NumberWithCommas(row.total.toFixed(2))}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>

                  {imgUrl && (
                    <Grid item xs={12} sm={3}>
                      <p>Pay Slip: </p>
                      <Paper className={classes.paper}>
                        <ModalImage small={imgUrl} large={imgUrl} alt={row?.invoice_no} />
                      </Paper>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box>
                      <div>
                        <div className={classes.root}>
                          <Grid container spacing={5}>
                            <Grid item xs={12} sm={12}>
                              <p>Status: {row.status}</p>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </>

            <div className={classes.root} style={{ marginTop: 35 }}>
              <Box component="span" displayPrint="none">
                <Grid container justify="center">
                  <Button variant="contained" onClick={handleCloseDialog}>
                    Go Back
                  </Button>
                </Grid>
              </Box>
            </div>
          </Box>
        </Grid>
      </div>
    </Dialog>
  );
};

export default Action;
