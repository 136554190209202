import React, { useState, useEffect } from 'react';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';

const customStyles = {
    headRow: {
        style: {
            backgroundColor: '#EFEFEF'
        },
    },
};

const columns = [
    {
        name: 'Filename',
        selector: 'filename',
        sortable: true,
    },
    {
        name: 'Upload Result',
        selector: 'message',
        sortable: true,
    },
];

const useStyles = makeStyles(theme => ({
    card: {
        margin: '0 auto',
        padding: 10,
        backgroundColor: lighten('#FFFFFF', 0.1),
        // backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
}));

const AlertUploadImageTable = (props) => {
    const { alerts } = props;
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <DataTable
                title="Notifications"
                columns={columns}
                data={alerts}
                customStyles={customStyles}
            />
        </Card>
    );
};

export default AlertUploadImageTable;