import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Button from '@material-ui/core/Button';
import { Visibility, Edit, NavigateNext, NavigateBefore } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatabaseService from '../../../services/database';
import memoize from 'memoize-one';
import { usePagination } from 'use-pagination-firestore';
import moment from 'moment';
import RBAC from '../../../helpers/Rbac';
import { fetchFilter, fetchSearch } from 'redux/actions/Search';
import EditOrder from './edit';
import OrderDetail from './detail';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#EFEFEF',
    },
  },
};

const columns = memoize((handleEdit, handleViewDetail) => [
  {
    cell: row =>
      RBAC({ allowedRoles: ['superadmin'] }) && <Edit onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    cell: row => <Visibility onClick={() => handleViewDetail(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px',
  },
  {
    name: 'Product Number',
    selector: 'po_number',
    sortable: true,
    width: '170px',
  },
  {
    name: 'Container',
    selector: 'container',
    sortable: true,
    width: '360px',
  },
  {
    name: 'Tracking Number',
    selector: 'tracking_number',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    cell: row => moment(row.date.toDate()).format('DD/MM/YYYY'),
    width: '120px',
  },
  {
    name: 'Pack',
    selector: 'packs',
    sortable: true,
  },
  {
    name: 'CBM',
    selector: 'cbm',
    sortable: true,
    cell: row => row.cbm?.toFixed(4),
  },
  {
    name: 'Customer',
    selector: 'customer_id',
    sortable: true,
    width: '150px',
  },
  {
    name: 'Sub',
    selector: 'sub_customer',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    width: '200px',
  },
]);

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <LinearProgress />
    </div>
  );
};

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(false);
  const [lastVisible, setLastVisible] = useState();
  const [status, setStatus] = useState('All');
  const { authUser } = useSelector(({ auth }) => auth);
  const { searchText } = useSelector(({ search }) => search);
  const { filterSearch } = useSelector(({ search }) => search);
  const [rowSelected, setRowSelected] = useState();

  const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
    DatabaseService.fetchOrdersData({
      authUser: authUser,
      status: status,
      filterSearch: filterSearch,
      searchText: searchText,
    }),
    {
      limit: 20,
    },
  );

  const handleChangeStatus = (event, value) => {
    if (value?.name) setStatus(value.name);
  };

  const handleEdit = row => {
    setRowSelected(row);
    setOpenEdit(true);
  };

  const handleViewDetail = row => {
    setRowSelected(row);
    setOpenDetail(true);
  };

  useEffect(() => {
    dispatch(fetchSearch(''));
    dispatch(
      fetchFilter([
        { key: 'po_number', name: 'Product Number' },
        { key: 'container', name: 'Container' },
        { key: 'tracking_number', name: 'Tracking Number' },
        { key: 'customer_id', name: 'Customer' },
        { key: 'sub_customer', name: 'Sub' },
      ]),
    );
  }, []);

  return (
    <GridContainer>
      <Grid item xs={4} container justify="flex-start">
        <Typography variant="h2">
          <IntlMessages id="page.home" />
        </Typography>
      </Grid>
      <Grid item xs={4}></Grid>

      <Grid item xs={4} container justify="flex-end">
        <Autocomplete
          closeIcon={false}
          size="small"
          options={authUser.type === 'driver' ? STATUS_DRIVER : STATUS}
          getOptionLabel={option => option.name}
          style={{ width: 300 }}
          value={STATUS.find(v => v.name === status) || {}}
          onChange={handleChangeStatus}
          renderInput={params => <TextField {...params} label="Status" variant="outlined" />}
        />
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Card className={classes.card}>
            <DataTable
              noHeader
              title="Registration Management"
              columns={columns(handleEdit, handleViewDetail)}
              data={items}
              progressPending={isLoading}
              progressComponent={<LinearIndeterminate />}
              customStyles={customStyles}
            />
          </Card>
          <Grid container justify="flex-end" spacing={5} style={{ marginTop: 5 }}>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={getPrev} disabled={isStart}>
                <NavigateBefore /> Previous
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" color="secondary" onClick={getNext} disabled={isEnd}>
                Next <NavigateNext />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {openEdit && <EditOrder rowSelected={rowSelected} open={openEdit} setOpen={setOpenEdit} />}
      {openDetail && <OrderDetail rowSelected={rowSelected} open={openDetail} setOpen={setOpenDetail} />}
    </GridContainer>
  );
};

export default Home;

const STATUS = [
  { name: 'All' },
  { name: 'WH China' },
  { name: 'Closed Container' },
  { name: 'WH Thai' },
  { name: 'Delivering' },
  { name: 'Delivered' },
  { name: 'Cancel' },
];

const STATUS_DRIVER = [{ name: 'All' }, { name: 'Delivering' }, { name: 'Delivered' }];
