import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import { useForm, Controller } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { firestore, storage } from '../../../firebase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ModalImage from 'react-modal-image';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import RBACChildren from '../../../helpers/RbacChildren';

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(5),
    marginTop: 20,
  },
  table: {
    minWidth: 650,
  },
  paper: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  content: {
    marginTop: 40,
    padding: '20px 35px 35px 35px',
  },
  stickyHeader: {
    maxHeight: 440,
  },
}));

const defaultValues = {
  po_number: '',
  date: '',
};

const OrderDetail = props => {
  const { open, setOpen, rowSelected } = props;
  const classes = useStyles();
  const history = useHistory();
  const { authUser } = useSelector(({ auth }) => auth);
  const { register, control, handleSubmit, reset, watch, setValue } = useForm({ defaultValues });
  const [data, setData] = useState();
  const [logData, setLogData] = useState([]);
  const [pending, setPending] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  // const id = rowSelected.id;
  const [imgUrls, setImgUrls] = useState([]);

  const getDetail = () => {
    setPending(true);

    // DatabaseService.getOrderDetail(id).then(async (res) => {
    const ref = firestore.collection('orders').doc(rowSelected.id);
    ref.onSnapshot(async doc => {
      const order = doc.data();
      if (!order) return;
      if (!order.po_number) history.goBack();

      setValue('po_number', order.po_number || 'N/A');
      setValue('date', new Date(order.date.toDate()));
      setValue('status', order.status || 'N/A');
      setValue('tracking_number', order.tracking_number || 'N/A');
      setValue('customer_id', order.customer_id || 'N/A');
      setValue('sub_customer', order.sub_customer || 'N/A');
      setValue('container', order.container || 'N/A');
      setValue('productType', order.productType || order.type || 'N/A');
      setValue('description', order.description || 'N/A');
      setValue('packs', order.packs || 'N/A');
      setValue('qty', order.qty || 'N/A');
      setValue('length', order.length || 'N/A');
      setValue('width', order.width || 'N/A');
      setValue('weight', order.weight || 'N/A');
      setValue('height', order.height || 'N/A');
      setValue('cbm', order.cbm.toFixed(4) || 'N/A');
      setValue('assigned_driver', order.assigned_driver || 'N/A');
      setValue('remark', order.remark || 'N/A');

      setData(order);
      // await setImage(order.po_number);

      setPending(false);
    });
    // })
  };

  // const setImage = async poNumber => {
  //     if (poNumber) {
  //         await storage
  //             .ref('images')
  //             .child(`${poNumber}`)
  //             .getDownloadURL()
  //             .then(url => {
  //                 setImgUrl(url);
  //             })
  //             .catch(async () => {
  //                 await storage
  //                     .ref('images')
  //                     .child(`${poNumber}.jpg`)
  //                     .getDownloadURL()
  //                     .then(url => {
  //                         setImgUrl(url);
  //                     })
  //                     .catch(() => {
  //                         console.log('Image not found!');
  //                     });
  //             });
  //     }
  // }

  const setImage = () => {
    setImgUrls([]);

    if (rowSelected?.pictures_path?.length > 0) {
      rowSelected.pictures_path.map(item => {
        storage
          .ref(item)
          .getDownloadURL()
          .then(url => {
            setImgUrls(oldArray => [...oldArray, url]);
          })
          .catch(() => {
            console.log('Image not found!');
          });
      });
    }
  };

  const fetchLogData = async () => {
    const refLog = firestore.collection('update_history');
    const query = refLog.where('po_id', '==', rowSelected.id);

    query.onSnapshot(snapShot => {
      let tempDataArray = [];
      snapShot.forEach(doc => {
        tempDataArray = [...tempDataArray, doc.data()];
      });

      setLogData(tempDataArray.sort((a, b) => new Date(b.updated_at.toDate()) - new Date(a.updated_at.toDate())));
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getDetail();
    setImage();
    if (authUser.type === 'superadmin') fetchLogData();
  }, []);

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>

          <Typography variant="h2">{`Detail of Product Number: ${data?.po_number ?? '...'}`}</Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Grid item xs={12}>
          <Box>
            {/* <Card className={classes.card}> */}
            <CardContent>
              {pending ? (
                <LinearProgress />
              ) : (
                <>
                  <div className={classes.root}>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Controller
                            name="date"
                            control={control}
                            render={({ field: { ref, ...rest } }) => (
                              <KeyboardDatePicker {...rest} label="Date" format="dd/MM/yyyy" fullWidth disabled />
                            )}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Product Number" fullWidth disabled />}
                          name="po_number"
                          control={control}
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={3}>
                                                <Controller
                                                    render={({ field }) =>
                                                        <TextField {...field}
                                                            label="Item Code"
                                                            fullWidth
                                                            disabled
                                                        />}
                                                    name="item_code"
                                                    control={control}
                                                />
                                            </Grid> */}

                      <Grid item xs={12} sm={2}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Status" fullWidth disabled />}
                          name="status"
                          control={control}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={3}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Tracking Number" fullWidth disabled />}
                          name="tracking_number"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Customer Id" fullWidth disabled />}
                          name="customer_id"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Sub" fullWidth disabled />}
                          name="sub_customer"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Container" fullWidth disabled />}
                          name="container"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Type" fullWidth disabled />}
                          name="productType"
                          control={control}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={2}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Description" fullWidth disabled />}
                          name="description"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Pack" fullWidth disabled />}
                          name="packs"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="QTY" fullWidth disabled />}
                          name="qty"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Length" fullWidth disabled />}
                          name="length"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Width" fullWidth disabled />}
                          name="width"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Weight" fullWidth disabled />}
                          name="weight"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Height" fullWidth disabled />}
                          name="height"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="CBM" fullWidth disabled />}
                          name="cbm"
                          control={control}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={3}>
                        <Controller
                          render={({ field }) => <TextField {...field} label="Assigned Driver" fullWidth disabled />}
                          name="assigned_driver"
                          control={control}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          //    label="Invoice No."
                          label={
                            <div>
                              Invoice No.{' '}
                              {data?.invoice_no && (
                                <RBACChildren allowedRoles={['frontadmin', 'accountant', 'delivery', 'superadmin']}>
                                  <a
                                    style={{ marginTop: '25px' }}
                                    href={`/billing-bill/no/${data.invoice_no}`}
                                    target="_blank">
                                    <LinkIcon />
                                  </a>
                                </RBACChildren>
                              )}
                            </div>
                          }
                          fullWidth
                          disabled
                          defaultValue={data?.invoice_no || 'N/A'}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Invoice Date"
                          fullWidth
                          disabled
                          defaultValue={
                            data?.created_bill_date && typeof data?.created_bill_date !== 'string'
                              ? moment(new Date(data?.created_bill_date.toDate())).format('L')
                              : 'N/A'
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          // label="Delivery Note No."
                          label={
                            <div>
                              Delivery Note No.{' '}
                              {data?.delivery_note_no && (
                                <RBACChildren allowedRoles={['delivery', 'superadmin']}>
                                  <a
                                    style={{ marginTop: '25px' }}
                                    href={`/billing-delivery/no/${data.delivery_note_no}`}
                                    target="_blank">
                                    <LinkIcon />
                                  </a>
                                </RBACChildren>
                              )}
                            </div>
                          }
                          fullWidth
                          disabled
                          defaultValue={data?.delivery_note_no || 'N/A'}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Delivery Note Date"
                          fullWidth
                          disabled
                          defaultValue={
                            data?.created_delivery_note_date && typeof data?.created_delivery_note_date !== 'string'
                              ? moment(new Date(data?.created_delivery_note_date.toDate())).format('L')
                              : 'N/A'
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <Grid container spacing={5} alignItems="center">
                      {/* {imgUrl && (
                                                <Grid item xs={12} sm={3} >
                                                    <Paper className={classes.paper}>
                                                        <ModalImage
                                                            small={imgUrl}
                                                            large={imgUrl}
                                                            alt={data?.po_number}
                                                        />
                                                    </Paper>
                                                </Grid>
                                            )} */}
                      {imgUrls[0] && (
                        <Grid item xs={12} sm={3}>
                          <Paper className={classes.paper}>
                            <ModalImage small={imgUrls[0]} large={imgUrls[0]} alt={data?.po} />
                          </Paper>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={imgUrls[0] ? 9 : 12}>
                        <Paper className={classes.paper}>
                          {data && (
                            <TableContainer>
                              <Table className={classes.table} size="small" aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Update By</TableCell>
                                    <TableCell>Pack</TableCell>
                                    <TableCell>Remark</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>WH China</TableCell>
                                    <TableCell>
                                      {data.update_arrived_wh_china?.toDate().toDateString() === 'Mon Jan 01 1900'
                                        ? 'TBD'
                                        : data.update_arrived_wh_china?.toDate().toDateString()}
                                    </TableCell>
                                    <TableCell>
                                      {data.update_arrived_wh_china_by ? data.update_arrived_wh_china_by : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                      {data.packs_arrived_wh_china ? data.packs_arrived_wh_china : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                      {data.update_wh_china_remark ? data.update_wh_china_remark : 'N/A'}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Closed Container</TableCell>
                                    <TableCell>
                                      {data.update_closed_container?.toDate().toDateString() === 'Mon Jan 01 1900'
                                        ? 'TBD'
                                        : data.update_closed_container?.toDate().toDateString()}
                                    </TableCell>
                                    <TableCell>
                                      {data.update_closed_container_by ? data.update_closed_container_by : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                      {data.packs_closed_container ? data.packs_closed_container : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                      {data.update_closed_container_remark ? data.update_closed_container_remark : 'N/A'}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>WH Thai</TableCell>
                                    <TableCell>
                                      {data.update_arrived_wh_thai?.toDate().toDateString() === 'Mon Jan 01 1900'
                                        ? 'TBD'
                                        : data.update_arrived_wh_thai?.toDate().toDateString()}
                                    </TableCell>
                                    <TableCell>
                                      {data.update_arrived_wh_thai_by ? data.update_arrived_wh_thai_by : 'N/A'}
                                    </TableCell>
                                    <TableCell>{data.packs_arrived_wh_thai ? data.packs_arrived_wh_thai : 'N/A'}</TableCell>
                                    <TableCell>
                                      {data.update_arrived_wh_thai_remark ? data.update_arrived_wh_thai_remark : 'N/A'}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Delivering</TableCell>
                                    <TableCell>
                                      {data.update_delivering?.toDate().toDateString() === 'Mon Jan 01 1900'
                                        ? 'TBD'
                                        : data.update_delivering?.toDate().toDateString()}
                                    </TableCell>
                                    <TableCell>{data.update_delivering_by ? data.update_delivering_by : 'N/A'}</TableCell>
                                    <TableCell>{data.packs_delivering ? data.packs_delivering : 'N/A'}</TableCell>
                                    <TableCell>
                                      {data.update_delivering_remark ? data.update_delivering_remark : 'N/A'}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Delivered</TableCell>
                                    <TableCell>
                                      {data.update_delivered?.toDate().toDateString() === 'Mon Jan 01 1900'
                                        ? 'TBD'
                                        : data.update_delivered?.toDate().toDateString()}
                                    </TableCell>
                                    <TableCell>{data.update_delivered_by ? data.update_delivered_by : 'N/A'}</TableCell>
                                    <TableCell>{data.packs_delivered ? data.packs_delivered : 'N/A'}</TableCell>
                                    <TableCell>
                                      {data.update_delivered_remark ? data.update_delivered_remark : 'N/A'}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={classes.root}>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={12}>
                        <Controller
                          render={({ field }) => (
                            <TextField {...field} multiline rows={4} label="Remark" fullWidth disabled />
                          )}
                          name="remark"
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  {authUser.type === 'superadmin' && logData.length > 0 && (
                    <Box mt={7} textAlign="center">
                      <Grid item xs={12} sm={12} mt={5}>
                        <h2>Update History</h2>
                        <Paper className={classes.paper}>
                          <TableContainer className={classes.stickyHeader}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Updated at</TableCell>
                                  <TableCell>Updated by</TableCell>
                                  <TableCell>Data before update</TableCell>
                                  <TableCell>Data after update</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {logData.map((item, index) => (
                                  <TableRow
                                    key={index}
                                    style={index % 2 ? { background: '#D1E8E4' } : { background: '#fafafa' }}>
                                    <TableCell>{item.updated_at.toDate().toString()}</TableCell>
                                    <TableCell>{item.updated_by}</TableCell>
                                    <TableCell>
                                      <p>{`Assigned Driver: ${item.old_data['assigned_driver']}`}</p>
                                      <p>{`CBM: ${item.old_data['cbm']}`}</p>
                                      <p>{`Container: ${item.old_data['container']}`}</p>
                                      <p>{`Date: ${item.old_data['date'].toDate().toDateString()}`}</p>
                                      <p>{`Description: ${item.old_data['description']}`}</p>
                                      <p>{`Height: ${item.old_data['height']}`}</p>
                                      <p>{`Length: ${item.old_data['length']}`}</p>
                                      <p>{`Pack: ${item.old_data['packs']}`}</p>
                                      <p>{`PO Number: ${item.old_data['po_number']}`}</p>
                                      <p>{`Type: ${item.old_data['productType']}`}</p>
                                      <p>{`QTY: ${item.old_data['qty']}`}</p>
                                      <p>{`Remark: ${item.old_data['remark']}`}</p>
                                      <p>{`Status: ${item.old_data['status']}`}</p>
                                      <p>{`Sub Customer: ${item.old_data['sub_customer']}`}</p>
                                      <p>{`Tracking Number: ${item.old_data['tracking_number']}`}</p>
                                      <p>{`Weight: ${item.old_data['weight']}`}</p>
                                      <p>{`Width: ${item.old_data['width']}`}</p>
                                    </TableCell>
                                    <TableCell>
                                      <p>{`Assigned Driver: ${item.new_data['assigned_driver']}`}</p>
                                      <p>{`CBM: ${item.new_data['cbm']}`}</p>
                                      <p>{`Container: ${item.new_data['container']}`}</p>
                                      <p>{`Date: ${item.new_data['date'].toDate().toDateString()}`}</p>
                                      <p>{`Description: ${item.new_data['description']}`}</p>
                                      <p>{`Height: ${item.new_data['height']}`}</p>
                                      <p>{`Length: ${item.new_data['length']}`}</p>
                                      <p>{`Pack: ${item.new_data['packs']}`}</p>
                                      <p>{`PO Number: ${item.new_data['po_number']}`}</p>
                                      <p>{`Type: ${item.new_data['productType']}`}</p>
                                      <p>{`QTY: ${item.new_data['qty']}`}</p>
                                      <p>{`Remark: ${item.new_data['remark']}`}</p>
                                      <p>{`Status: ${item.new_data['status']}`}</p>
                                      <p>{`Sub Customer: ${item.new_data['sub_customer']}`}</p>
                                      <p>{`Tracking Number: ${item.new_data['tracking_number']}`}</p>
                                      <p>{`Weight: ${item.new_data['weight']}`}</p>
                                      <p>{`Width: ${item.new_data['width']}`}</p>
                                    </TableCell>
                                    {/* <TableCell>{Object.keys(item.old_data).sort().map((key, index) => (
                                                                                    <p key={index}>{`${key}: ${item.old_data[key] || 'N/A'}, `}</p>
                                                                                ))}</TableCell> */}
                                    {/* <TableCell>{Object.keys(item.new_data).sort().map((key, index) => (
                                                                                    <p key={key}>{`${key}: ${item.new_data[key] || 'N/A'}, `}</p>
                                                                                ))}</TableCell> */}
                                    {/* <TableCell>{JSON.stringify(item.old_data)}</TableCell>
                                                                                <TableCell>{JSON.stringify(item.new_data)}</TableCell> */}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </Grid>
                    </Box>
                  )}

                  <div className={classes.root} style={{ marginTop: 20 }}>
                    <Grid container justify="center">
                      <Button variant="contained" onClick={handleClose}>
                        Go Back
                      </Button>
                    </Grid>
                  </div>
                </>
              )}
            </CardContent>
            {/* </Card> */}
          </Box>
        </Grid>
      </div>
    </Dialog>
  );
};

export default OrderDetail;
