import { GET_ALL_CUSTOMERS, GET_ALL_SUB, GET_ALL_DRIVER } from '../../@jumbo/constants/ActionTypes';

export const fetchAllCustomers = customers => {
  return dispatch => {
    dispatch({
      type: GET_ALL_CUSTOMERS,
      payload: customers,
    });
  };
};

export const fetchAllSub = sub => {
  return dispatch => {
    dispatch({
      type: GET_ALL_SUB,
      payload: sub,
    });
  };
};

export const fetchAllDrivers = drivers => {
  return dispatch => {
    dispatch({
      type: GET_ALL_DRIVER,
      payload: drivers,
    });
  };
};
