const uri = process.env.REACT_APP_API_ENDPOINT;

const Api = {
  // addOrders: orders => {
  //   return new Promise((resolve, reject) => {
  //     fetch(`${uri}/order`, {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //         Authorization: localStorage.getItem('token'),
  //       },
  //       body: JSON.stringify(orders),
  //     })
  //       .then(res => {
  //         if (res.status === 200 || res.status === 201)
  //           resolve(res);
  //         else
  //           reject(res.statusText);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },

  addArrivedWhChina: orders => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/order/importJsonArrivedWhChina`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify(orders),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addClosedContainer: orders => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/order/importJsonClosedContainer`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify(orders),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // updateOrders: orders => {
  //   return new Promise((resolve, reject) => {
  //     fetch(`${uri}/order/updateOrders`, {
  //       method: 'PUT',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //         Authorization: localStorage.getItem('token'),
  //       },
  //       body: JSON.stringify(orders),
  //     })
  //       .then(res => {
  //         if (res.status === 200 || res.status === 201)
  //           resolve(res.data);
  //         else
  //           reject(res.statusText);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },

  // updateOrdersRemark: (select, filter, remark, checkRemarked, flag) => {
  //   return new Promise((resolve, reject) => {
  //     fetch(`${uri}/order/updateOrdersRemark`, {
  //       method: 'PUT',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //         Authorization: localStorage.getItem('token'),
  //       },
  //       body: JSON.stringify({
  //         select: select,
  //         filter: filter,
  //         remark: remark,
  //         checkRemarked: checkRemarked,
  //         remarked: flag,
  //       }),
  //     })
  //       .then(res => {
  //         if (res.status === 200 || res.status === 201)
  //           resolve(res.data);
  //         else
  //           reject(res.statusText);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },

  updateOrdersRemark: (data, remark, flag) => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/order/updateOrdersRemark`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify({
          data: data,
          remark: remark,
          remarked: flag,
        }),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res.data);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  clearRemarkedByPo: data => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/order/clear-remarked-by-po`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify({ data: data }),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res.data);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  clearRemarkedByContainer: data => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/order/clear-remarked-by-container`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify({ data: data }),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res.data);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateOrdersByContainer: (containerId, data) => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/order/updateOrderByContainer`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify({ containerId: containerId, data: data }),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res.data);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateOrdersDeliveringByProductNumberList: (data, productNumberList) => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/order/updateOrdersDeliveringByProductNumberList`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify({ data: data, productNumberList: productNumberList }),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res.data);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateOrdersDeliveredByProductNumberList: (data, productNumberList) => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/order/updateOrdersDeliveredByProductNumberList`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify({ data: data, productNumberList: productNumberList }),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res.data);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  approveUser: user => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/register/approve`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify(user),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  changePasswordUser: user => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/user/change-password`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify(user),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteUser: user => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/user`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify(user),
      })
        .then(res => {
          if (res.status === 200 || res.status === 201) resolve(res);
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  approveDriver: user => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/register/approve-driver`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify(user),
      })
        .then(async res => {
          if (res.status === 200 || res.status === 201) resolve(res);
          else reject(await res.text());
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addDelivery: data => {
    return new Promise((resolve, reject) => {
      fetch(`${uri}/delivery`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify(data),
      })
        .then(async res => {
          if (res.status === 200 || res.status === 201) resolve(await res.json());
          else reject(res.statusText);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};

export default Api;
