import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import Button from '@material-ui/core/Button';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Add, AssignmentTurnedIn, Edit, Delete, NavigateNext, NavigateBefore } from '@material-ui/icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import memoize from 'memoize-one';
import { firestore } from '../../../../firebase';
import Api from 'services/api.service';
import Action from './action';
import { usePagination } from 'use-pagination-firestore';
import DatabaseService from 'services/database';
import { fetchFilter, fetchSearch } from 'redux/actions/Search';
import ChangePasswordDialog from './changePasswordDialog';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Sample Page', isActive: true },
];

const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#EFEFEF',
    },
  },
};

const columns = memoize((handleEdit, handleDelete, handleChangePassword, checkPermission) => [
  {
    cell: row => checkPermission(row.type) && <Edit onClick={() => handleDelete(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px', // custom width for icon button
  },
  {
    cell: row => checkPermission(row.type) && <Delete onClick={() => handleEdit(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px', // custom width for icon button
  },
  {
    cell: row =>
      checkPermission(row.type) && <VpnKeyIcon onClick={() => handleChangePassword(row)} style={{ cursor: 'pointer' }} />,
    allowOverflow: true,
    button: true,
    width: '36px', // custom width for icon button
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: 'mobile',
    sortable: true,
  },
  {
    name: 'Line',
    selector: 'line_id',
    sortable: true,
  },
  {
    name: 'Company',
    selector: 'company',
    sortable: true,
  },
  {
    name: 'Customer Id',
    selector: 'customer_id',
    sortable: true,
  },
  {
    name: 'Sub Id',
    selector: 'sub_id',
    sortable: true,
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: true,
  },
]);

const useStyles = makeStyles(theme => ({
  card: {
    margin: '0 auto',
    padding: 10,
    backgroundColor: lighten('#FFFFFF', 0.1),
    width: '100%',
    // backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <LinearProgress />
    </div>
  );
};

const User = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(DatabaseService.getAllCustomers());
    dispatch(DatabaseService.getAllSub());
  };
  const { authUser } = useSelector(({ auth }) => auth);
  const { searchText } = useSelector(({ search }) => search);
  const { filterSearch } = useSelector(({ search }) => search);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [typeAction, setTypeAction] = useState();
  const [openPassword, setOpenPassword] = useState(false);

  const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
    // firestore.collection("/users").orderBy("email", "asc"),
    DatabaseService.fetchManagementData({
      authUser: authUser,
      collection: 'users',
      filterSearch: filterSearch,
      searchText: searchText,
    }),
    {
      limit: 20,
    },
  );

  // const getAllRegister = async () => {
  //     // await DatabaseService.getAllRegistrationsUser().then((res) => {
  //     //     setData(() => res);
  //     // });
  //     setPending(true);

  //     const ref = firestore.collection('users');
  //     ref.onSnapshot(snapShot => {
  //         let tempDataArray = [];
  //         snapShot.forEach(doc => {
  //             if (doc.exists) {
  //                 tempDataArray = [
  //                     ...tempDataArray,
  //                     {
  //                         uid: doc.id,
  //                         email: doc.data().email,
  //                         password: doc.data().password,
  //                         name: doc.data().name,
  //                         mobile: doc.data().mobile,
  //                         line_id: doc.data().line_id,
  //                         customer: doc.data().customer_id ?? '',
  //                         customer_id: doc.data().customer_id ?? '',
  //                         type: doc.data().type,
  //                     },
  //                 ];
  //             }
  //         });
  //         setData(tempDataArray);
  //         setPending(false);
  //     });
  // }

  const handleDialog = () => {
    setTypeAction();
    setSelectedUser();
    setOpen(false);
  };

  const handleEdit = row => {
    setTypeAction('edit');
    setSelectedUser(row);
    setOpen(true);
  };

  const handleDelete = row => {
    setTypeAction('delete');
    setSelectedUser(row);
    setOpen(true);
  };

  const handleChangePassword = row => {
    setSelectedUser(row);
    setOpenPassword(true);
  };

  const checkPermission = type => {
    switch (authUser.type) {
      case 'admin':
        if (type !== 'superadmin') {
          return true;
        }
        break;
      case 'superadmin':
        return true;
      default:
        return false;
    }
  };

  // const getAllCustomers = () => {
  //     DatabaseService.getAllCustomers().then((res) => {
  //         console.log(res)
  //     });
  // }

  // useEffect(() => {
  //     getAllCustomers();
  // }, []);

  useEffect(() => {
    fetchData();

    dispatch(fetchSearch(''));
    dispatch(
      fetchFilter([
        { key: 'name', name: 'Name' },
        { key: 'email', name: 'Email' },
        { key: 'customer_id', name: 'Customer Id' },
        { key: 'type', name: 'Type' },
      ]),
    );
  }, []);

  return (
    // <PageContainer heading={< IntlMessages id="pages.registrationManagement" />} breadcrumbs={breadcrumbs} >
    <GridContainer>
      <Grid item xs={4} container justify="flex-start">
        <Typography variant="h2">
          <IntlMessages id="page.userManagement" />
        </Typography>
      </Grid>
      <Grid item xs={4}></Grid>

      <Grid item xs={12}>
        <Box>
          <Card className={classes.card}>
            <DataTable
              noHeader
              title="User Management"
              columns={columns(handleDelete, handleEdit, handleChangePassword, checkPermission)}
              data={items}
              // selectableRows
              progressPending={isLoading}
              progressComponent={<LinearIndeterminate />}
              customStyles={customStyles}
            />

            {open && <Action open={open} handleDialog={handleDialog} selectedUser={selectedUser} typeAction={typeAction} />}
            {openPassword && (
              <ChangePasswordDialog
                openPassword={openPassword}
                setOpenPassword={setOpenPassword}
                data={data}
                setData={setData}
                row={selectedUser}
                setRow={setSelectedUser}
              />
            )}
          </Card>
          <Grid container justify="flex-end" spacing={5} style={{ marginTop: 5 }}>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={getPrev} disabled={isStart}>
                <NavigateBefore /> Previous
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" color="secondary" onClick={getNext} disabled={isEnd}>
                Next <NavigateNext />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </GridContainer>
    // </PageContainer >
  );
};

export default User;
