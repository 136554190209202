import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { CurrentAuthMethod } from '../../../../@jumbo/constants/AppConstants';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { NotificationManager } from 'react-notifications';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { EditButton, DeleteButton } from '../../../../components/ColorButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Api from '../../../../services/api.service';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
}));

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is Required')
    .min(8, 'Password must be at least 8 characters'),
  password_confirmation: yup
    .string()
    .required('Confirm Password is Required')
    .oneOf([yup.ref('password'), null], 'Passwords do not match'),
});

const defaultValues = {
  password: '',
  password_confirmation: '',
};

export default function ChangePasswordDialog(props, { method = CurrentAuthMethod }) {
  const { openPassword, setOpenPassword, row, setRow } = props;
  const user = row;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const [pending, setPending] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues });
  const [roles, setRoles] = useState([]);
  const [hasRoles, setHasRoles] = useState([]);

  const handleClose = () => {
    setRow(null);
    reset();
    setOpenPassword(false);
  };

  const onSubmit = async e => {
    setPending(true);
    user.newPassword = e.password;
    Api.changePasswordUser(user)
      .then(() => {
        if (authUser.email === user.email) {
          handleClose();
          setPending(false);
          window.location.reload();
        } else {
          handleClose();
          setPending(false);
          NotificationManager.success('Password has been changed', 'Success');
        }
      })
      .catch(err => {
        NotificationManager.error(err, 'Error');
      });
  };

  return (
    <Box>
      <Dialog open={openPassword} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.dialogTitleRoot}>Change Password</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField label="Email" type="text" value={user?.email} fullWidth disabled />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Password"
                      type="password"
                      error={errors.password && true}
                      helperText={errors.password?.message}
                      inputProps={{ maxLength: 255 }}
                      fullWidth
                    />
                  )}
                  name="password"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Confirm Password"
                      type="password"
                      error={errors.password_confirmation && true}
                      helperText={errors.password_confirmation?.message}
                      inputProps={{ maxLength: 255 }}
                      fullWidth
                    />
                  )}
                  name="password_confirmation"
                  control={control}
                />
              </Grid>
            </Grid>

            {pending && <LinearProgress style={{ marginTop: '10px' }} color="secondary" className={classes.linear} />}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} variant="contained" disabled={pending}>
              Cancel
            </Button>
            <EditButton type="submit" variant="contained" color="primary" disabled={pending}>
              Update
            </EditButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}
