import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { firestore, storage } from '../../../../../firebase';
import DatabaseService from '../../../../../services/database';
import { NotificationManager } from 'react-notifications';
import { EditButton, DeleteButton } from '../../../../../components/ColorButton';
import moment from 'moment';
import NumberWithCommas from 'helpers/NumberWithCommas';
import deliveryNoteStatus from 'helpers/DeliveryNoteStatus';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ModalImage from 'react-modal-image';
import Paper from '@material-ui/core/Paper';
import PrintDeliveryNote from '../Print/print';
import '../css/action.css';
import RBAC from '../../../../../helpers/Rbac';

const useStyles = makeStyles(theme => ({
  content: {
    padding: 100,
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 18,
      // color: theme.palette.common.dark,
      color: theme.palette.common.white,
    },
    backgroundColor: '#2090E9',
  },
  linear: {
    marginBottom: 10,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#97C1E0 !important',
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
  },
}));

const MySwal = withReactContent(Swal);

const DeliveryNoteDetail = ({ open, setOpenAction, selectedRow, typeAction, showBy, typePage }) => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { drivers } = useSelector(({ master }) => master);
  const [openDetail, setOpenDetail] = useState(false);
  const [row, setRow] = useState(selectedRow);
  const [customStatus, setCustomStatus] = useState(row.status);
  const [imgUrls, setImgUrls] = useState([]);

  const handleCloseDialog = () => {
    setOpenAction(false);
  };

  const updateStatus = status => {
    // handleCloseDialog();

    MySwal.fire({
      title: 'Please Confirm',
      text: `Comfirm to change status to ${status}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        DatabaseService.updateDeliveryNoteStatus({
          id: row.id,
          status: status,
          update_status_by: authUser.email,
          update_status_at: new Date(),
        })
          .then(() => {
            // handleCloseDialog();
            setRow({ ...row, status: status });
            setCustomStatus(status);
            NotificationManager.success('Status has been updated', 'Success');
          })
          .catch(err => {
            NotificationManager.error(err, 'Fail');
          });
      }
    });
  };

  const selectDriver = () => {
    let options = {};

    drivers.map(item => {
      options[item.name] = item.name;
    });

    Swal.fire({
      title: 'Select driver',
      text: 'Upload driver to change status to Delivering',
      input: 'select',
      inputOptions: options,
      inputPlaceholder: 'Select driver',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      preConfirm: async driver => {
        if (!driver) {
          Swal.showValidationMessage('<i class="fa fa-info-circle"></i> driver is required');
        } else {
          DatabaseService.updateDeliveryNoteStatus({
            id: row.id,
            status: 'Delivering',
            update_status_by: authUser.email,
            update_status_at: new Date(),
            driver: driver,
          })
            .then(() => {
              setRow({ ...row, driver: driver, status: 'Delivering' });
              setCustomStatus('Delivering');
              NotificationManager.success('Status has been updated', 'Success');
            })
            .catch(err => {
              NotificationManager.error(err, 'Fail');
            });
        }
      },
    });
  };

  const uploadDelivered = () => {
    Swal.fire({
      title: 'Select delivery confirmation',
      text: 'Upload delivery confirmation to change status to Delivered',
      input: 'file',
      inputAttributes: {
        accept: 'image/*',
        multiple: 'multiple',
        'aria-label': 'Upload your delivery confirmation',
      },
      showCancelButton: true,
      confirmButtonText: 'Upload',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      preConfirm: async files => {
        if (!files) {
          Swal.showValidationMessage('<i class="fa fa-info-circle"></i> delivery confirmation is required');
        } else {
          // let pictures = [];
          let idx = 0;
          row.pictures_path = row.pictures_path || [];

          for (let file of files) {
            let pictureName =
              row.pictures_path.length === 0
                ? `${row.delivery_note_no}`
                : `${row.delivery_note_no}-${row.pictures_path.length + 1}`;
            await DatabaseService.uploadDelivered({ file: file, pictureName: pictureName }).then(async res => {
              // await storage
              //     .ref('delivered')
              //     .child(`${row.delivery_note_no}-${idx + 1}`)
              //     .getDownloadURL()
              //     .then(url => {
              //         pictures.push({ fullPath: res.metadata.fullPath, downloadPath: url });
              //     })
              //     .catch(() => {
              //         console.log('Image not found!');
              //     });

              // pictures.push(`delivered/${row.delivery_note_no}-${idx + 1}`);
              row.pictures_path.push(res.metadata.fullPath);
              idx++;
            });
          }

          DatabaseService.updateDeliveryNoteStatus({
            id: row.id,
            status: 'Delivered',
            update_status_by: authUser.email,
            update_status_at: new Date(),
            picturesPath: row.pictures_path,
          })
            .then(() => {
              // handleCloseDialog();
              setRow({ ...row, status: 'Delivered', pictures_path: row.pictures_path });
              setCustomStatus('Delivered');
              NotificationManager.success('Status has been updated', 'Success');
            })
            .catch(err => {
              NotificationManager.error(err, 'Fail');
            });
          // });
        }
      },
    });
  };

  const handleCancel = () => {
    handleCloseDialog();

    MySwal.fire({
      title: 'Please Confirm',
      html: `Comfirm to cancel Delivery Note Number <br/> ${row.delivery_note_no}? <br/>`,
      icon: 'warning',
      input: 'textarea',
      inputPlaceholder: 'Remark here...',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      preConfirm: value => {
        if (!value) {
          Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Remark is required');
        } else {
          DatabaseService.cancelDeliveryNoteStatus({
            id: row.id,
            remark: value,
            cancel_by: authUser.email,
            cancel_at: new Date(),
          })
            .then(() => {
              setRow({ ...row, status: 'Cancel' });
              setCustomStatus('Cancel');
              NotificationManager.success('Delivery Note has been deleted', 'Success');
            })
            .catch(err => {
              NotificationManager.error(err, 'Fail');
            });
        }
      },
    });
  };

  const handleChangeCustomStatus = (event, value) => {
    if (value?.name) setCustomStatus(value.name);
  };

  const handleCustomUpdateStatus = () => {
    if (authUser.type !== 'superadmin') return;

    MySwal.fire({
      title: 'Please Confirm',
      text: `Comfirm to change status to ${customStatus}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        DatabaseService.updateDeliveryNoteStatus({
          id: row.id,
          status: customStatus,
          update_status_by: authUser.email,
          update_status_at: new Date(),
          customUpdate: true,
        })
          .then(() => {
            // handleCloseDialog();
            setRow({ ...row, status: customStatus });
            NotificationManager.success('Status has been updated', 'Success');
          })
          .catch(err => {
            NotificationManager.error(err, 'Fail');
          });
      }
    });
  };

  const setImage = () => {
    setImgUrls([]);

    if (row?.pictures_path?.length > 0) {
      row.pictures_path.map(item => {
        storage
          .ref(item)
          .getDownloadURL()
          .then(url => {
            setImgUrls(oldArray => [...oldArray, url]);
          })
          .catch(() => {
            console.log('Image not found!');
          });
      });
    }
  };

  const handelSetStatusPrinted = () => {
    setRow({ ...row, status: 'Printed' });
    setCustomStatus('Printed');
  };

  useEffect(() => {
    if (row) setImage();
  }, [row]);

  return (
    <div className={typePage !== 'newpage' ? classes.content : ''}>
      <Grid item xs={12}>
        <Box>
          <div key={row.delivery_note_no}>
            <div className={classes.root}>
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}>
                  <strong>ลูกค้า: </strong>
                  <span>{row.customer_id}</span>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <strong>วันที่: </strong>
                  <span>
                    {moment(row.delivery_note_date.toDate())
                      .locale('th')
                      .add(543, 'year')
                      .format('LL')}
                  </span>
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}>
                  <strong>Sub: </strong>
                  <span>{row.sub ?? ''}</span>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <strong>เลขที่: </strong>
                  <span>{row.delivery_note_no}</span>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}>
                  <strong>ที่อยู่: </strong>
                  <span>{row.company_address}</span>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <strong>อ้างอิง: </strong>
                  <span>{row.container}</span>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}>
                  <strong>โทรศัพท์: </strong>
                  <span>{row.mobile}</span>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <strong>ผู้ส่งสินค้า: </strong>
                  <span>{row.driver}</span>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6}></Grid>

                <Grid item xs={6} sm={6}>
                  <strong>ผู้สร้างรายการ: </strong>
                  <span>{row.created_by}</span>
                </Grid>
              </Grid>
            </div>

            <div style={{ marginTop: 20 }}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <table className={classes.table}>
                    <thead>
                      <tr className={classes.tr}>
                        <th className={classes.th}>Date</th>
                        <th className={classes.th}>Bill No.</th>
                        <th className={classes.th}>Description</th>
                        <th className={classes.th}>Pack</th>
                        <th className={classes.th}>Weight(kgs)</th>
                        <th className={classes.th}>Length(CM)</th>
                        <th className={classes.th}>Width(CM)</th>
                        <th className={classes.th}>Height(CM)</th>
                        <th className={classes.th}>CBM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.productNumberList?.map(item => (
                        <tr key={item.po_number}>
                          <td className={classes.td}>
                            {moment(new Date((item.date.seconds + item.date.nanoseconds * 10 ** -9) * 1000)).format(
                              'DD/MM/YYYY',
                            )}
                          </td>
                          <td className={classes.td}>{item.po_number}</td>
                          <td className={classes.td}>{item.description}</td>
                          <td className={classes.td}>{NumberWithCommas(item.packs)}</td>
                          <td className={classes.td}>{NumberWithCommas(item.weight.toFixed(2))}</td>
                          <td className={classes.td}>{NumberWithCommas(item.length.toFixed(2))}</td>
                          <td className={classes.td}>{NumberWithCommas(item.width.toFixed(2))}</td>
                          <td className={classes.td}>{NumberWithCommas(item.height.toFixed(2))}</td>
                          <td className={classes.td}>{item.cbm.toFixed(4)}</td>
                        </tr>
                      ))}
                      <tr>
                        <td className={classes.td}></td>
                        <td className={classes.td}>Grand Total</td>
                        <td className={classes.td}></td>
                        <td className={classes.td}>{NumberWithCommas(row.total_pack)}</td>
                        <td className={classes.td}>{NumberWithCommas(row.total_weight.toFixed(2))}</td>
                        <td className={classes.td}></td>
                        <td className={classes.td}></td>
                        <td className={classes.td}></td>
                        <td className={classes.td}>{row.total_cbm.toFixed(4)}</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>

                {imgUrls?.length > 0 &&
                  imgUrls.map((item, idx) => (
                    <Grid item xs={12} sm={3} key={item}>
                      <p>{idx === 0 ? `Delivery Confirmation:` : <br />}</p>
                      <Paper className={classes.paper}>
                        <ModalImage small={item} large={item} alt={row?.invoice_no} />
                      </Paper>
                    </Grid>
                  ))}

                <Grid item xs={12}>
                  <Box>
                    <div>
                      <div className={classes.root}>
                        <Grid container spacing={5}>
                          {authUser.type === 'superadmin' && typeAction === 'Edit' ? (
                            <>
                              <Grid item xs={12} sm={12}>
                                <Autocomplete
                                  style={{ display: 'inline-block', width: 200 }}
                                  closeIcon={false}
                                  size="small"
                                  options={deliveryNoteStatus}
                                  getOptionLabel={option => option.name}
                                  value={deliveryNoteStatus.find(v => v.name === customStatus) || {}}
                                  onChange={handleChangeCustomStatus}
                                  renderInput={params => <TextField {...params} label="Status" variant="outlined" />}
                                />

                                <EditButton
                                  type="submit"
                                  style={{ marginLeft: 10, marginTop: 15 }}
                                  variant="contained"
                                  onClick={handleCustomUpdateStatus}>
                                  Update
                                </EditButton>
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12} sm={12}>
                              <p>Status: {row.status}</p>
                            </Grid>
                          )}
                        </Grid>

                        {row.status === 'Cancel' && (
                          <Grid container spacing={5}>
                            <Grid item xs={12} sm={12}>
                              <p>Remark: {row.remark}</p>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.root} style={{ marginTop: 15 }}>
            <Box component="span" displayPrint="none">
              <Grid container justify="center">
                {typePage === 'newpage' ? (
                  <Button variant="contained" onClick={() => (window.location.href = '/billing-delivery')}>
                    Close
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleCloseDialog}>
                    Go Back
                  </Button>
                )}

                {RBAC({ allowedRoles: ['superadmin'] }) && typeAction === 'Edit' && row.status !== 'Cancel' && (
                  <Box ml={2}>
                    <DeleteButton variant="contained" onClick={handleCancel}>
                      Cancel
                    </DeleteButton>
                  </Box>
                )}
                {typeAction === 'Edit' && row.status === 'Created' && (
                  <Box ml={2}>
                    <Button color="primary" variant="contained" onClick={() => setOpenDetail(true)}>
                      Print
                    </Button>
                  </Box>
                )}
                {typeAction === 'Edit' && row.status === 'Printed' && (
                  <Box ml={2}>
                    <Button color="primary" variant="contained" onClick={selectDriver}>
                      Delivering(Select Driver)
                    </Button>
                  </Box>
                )}
                {typeAction === 'Edit' && row.status === 'Delivering' && (
                  <Box ml={2}>
                    <Button color="primary" variant="contained" onClick={uploadDelivered}>
                      Upload Delivered
                    </Button>
                  </Box>
                )}
                {typeAction === 'Edit' && row.status === 'Delivered' && (
                  <Box ml={2}>
                    <Button color="primary" variant="contained" onClick={() => updateStatus('Confirm Delivered')}>
                      Confirm Delivered
                    </Button>
                  </Box>
                )}
              </Grid>
            </Box>
          </div>
        </Box>
      </Grid>
      {openDetail && (
        <PrintDeliveryNote
          invoices={[row]}
          open={openDetail}
          setOpenDetail={setOpenDetail}
          setOpenDialog={setOpenAction}
          printBy={'DeliveryNote'}
          setStatusPrinted={handelSetStatusPrinted}
        />
      )}
    </div>
  );
};

export default DeliveryNoteDetail;
