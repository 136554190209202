import React, { useState, useEffect } from 'react';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Box, Button, List, ListItem, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { Alert, AlertTitle } from '@material-ui/lab';

const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Import Arrived WH China', isActive: true },
];

const useStyles = makeStyles(theme => ({
    card: {
        margin: '0 auto',
        padding: 10,
        backgroundColor: lighten('#FFFFFF', 0.1),
        // backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
}));

const UploadStatus = ({ setUploadted, alert, type }) => {
    const classes = useStyles();

    const goBack = () => {
        setUploadted(false);
    }

    return (
        <>
            <Card className={classes.card}>
                <Alert severity="success">
                    <AlertTitle>Upload Finished</AlertTitle>
                    {type !== 'image' &&
                        <div style={{ marginTop: 10 }}>
                            Total <strong>{alert.all} {alert.all > 1 ? 'rows' : 'row'}</strong>
                        </div>
                    }
                    <div>
                        Success <strong>{alert.success} {alert.success > 1 ? 'rows' : 'row'}</strong>
                    </div>
                    {type !== 'image' &&
                        <div>
                            Warning <strong>{alert.warning} {alert.warning > 1 ? 'rows' : 'row'}</strong>
                        </div>
                    }
                    <div>
                        Error <strong>{alert.error} {alert.error > 1 ? 'rows' : 'row'}</strong>
                    </div>
                </Alert>
            </Card>

            <div style={{ textAlign: 'center', marginTop: 10 }}>
                <Button variant="outlined" type="button" onClick={goBack}>
                    Upload Again
                </Button>
            </div>
        </>
    );
};

export default UploadStatus;