import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MessageIcon from '@material-ui/icons/Message';
import ShareIcon from '@material-ui/icons/Share';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import CmtMediaObject from '../../../../../../@coremat/CmtMediaObject';
import CmtAvatar from '../../../../../../@coremat/CmtAvatar';
import { getDateElements } from '../../../../../utils/dateHelper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core';
import { Schedule, CheckCircle, Warning, Error } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  feedItemRoot: {
    padding: '10px 0 15px 5px',
    position: 'relative',
    borderBottom: `1px solid ${fade(theme.palette.common.dark, 0.035)}`,
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
    '& .Cmt-media-image': {
      alignSelf: 'flex-start',
      width: 56,
    },
    '& .Cmt-media-body': {
      width: 'calc(100% - 56px)',
      flex: 'inherit',
    },
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer',
  },
}));

const icons = {
  DATE: <Schedule style={{ color: '#836AFF' }} />,
};

const getImportContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="blue">
      {item.type}
    </Box>
    <Box component="span" ml={1}>
      {item.message}
    </Box>
  </Typography>
);

const NotificationItem = ({ item }) => {
  const classes = useStyles();

  const getTitle = (item, classes) => {
    return getImportContent(item, classes);
  };

  const getSubTitle = () => (
    <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
      <Box fontSize={16} clone>
        {icons['DATE']}
      </Box>
      <Box ml={2}>{moment(item.alert_date.toDate()).format('lll')}</Box>
    </Box>
  );

  const getAvatar = (item) => {
    switch (item.type) {
      case 'Success':
        return <CmtAvatar color="green" size="small" variant="circle">
          <CheckCircle />
        </CmtAvatar>
      case 'Warning':
        return <CmtAvatar color="yellow" size="small" variant="circle">
          <Warning />
        </CmtAvatar>
      case 'Error':
        return <CmtAvatar color="red" size="small" variant="circle">
          <Error />
        </CmtAvatar>
    }
  }

  return (
    <Box className={classes.feedItemRoot} style={{ backgroundColor: item.status === 0 && '#CADCF4', }}>
      <CmtMediaObject
        avatarPos="center"
        avatar={getAvatar(item)}
        title={getTitle(item, classes)}
        subTitle={getSubTitle()}
      />
    </Box>
  );
};

export default NotificationItem;
